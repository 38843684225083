import logo from './logo.svg';
import './App.css';
import './components/styles/scss/main.scss'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import HomePage from "./components/HomePage/homePage";
import AiraPropertyDetails from "./components/AiraPropertyDetailsPage/airaPropertyDetails";
import About from "./components/AboutUs/about";
import ContactUs from "./components/ContactUs/contactUs";
import BhuvanPropertyDetails from "./components/BhuvanPropertyDetailsPage/bhuvanPropertyDetails";
import TermsAndConditionsPage from "./components/TermsAndConditions/termsAndConditionsPage";
import CafePage from "./components/CafePage/cafePage";
import GamingPage from "./components/GamingPage/gamingPage";
import GameBookingForm from "./components/GamingBookingForm/gameBookingForm";
import CateringBookingPage from "./components/CateringBookingForm/cateringBookingPage";
function App() {
  return (
    <div className="">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/exploreproperties/:id" element={<AiraPropertyDetails/>}/>
                {/*<Route path="/explorebhuvanproperties" element={<BhuvanPropertyDetails/>}/>*/}
                <Route path="/about" element={<About/>}/>
                <Route path="/contactus" element={<ContactUs/>}/>
                <Route path="/termsandconditions" element={<TermsAndConditionsPage/>}/>
                <Route path="/cafe" element={<CafePage />} />
                <Route path="/gaming" element={<GamingPage />} />
                <Route path="/bookgame" element={<GameBookingForm/>} />
                <Route path="/catering" element={<CateringBookingPage/>} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
