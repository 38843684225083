import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {Link} from "react-router-dom";
// import { motion, useTransform, useScroll } from "framer-motion";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import Flip from "gsap/Flip";
// import Draggable from "gsap/Draggable";
// import gsap from "gsap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination, Navigation, Mousewheel} from "swiper/modules";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/autoplay";
import 'swiper/css/navigation';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AOS from "aos";
import "aos/dist/aos.css";

import * as Icon from 'react-feather'


import propertyDetails from '../Models/propertyDetails.json'
import gameData from '../Models/gamingDetails.json'
import cafeData from "../Models/cafeDetails.json"
import {Sliders} from "react-feather";

const HomePage = () => {

    const [activePropertyTab, setActivePropertyTab] = useState(propertyDetails[0].id);
    const [activeAmenitiesSlideIndex, setActiveAmenitiesSlideIndex] = useState(0);
    const [activeMobileAmenitiesSlideIndex, setActiveMobileAmenitiesSlideIndex] = useState(0);
    const [backgroundAmenitiesImage, setAmenitiesBackgroundImage] = useState("images/landing/bg-cafeteria-image.png");
    const [backgroundAmenitiesMobileImage, setAmenitiesBackgroundMobileImage] = useState("images/landing/bg-cafeteria-image.png");
    const amenitiesBgImage = ["images/landing/bg-dark-color-cafeteria-image.png"];
    const amenitiesHeading = ['Cafeteria', 'Gym', 'Luxury Rooms'];
    const amenitiesDesc = ['Our cafeteria is a space where you can relax, socialise, and enjoy delicious meals along with a variety of healthy snacks under one roof.',
        'Get fit and feel great with our fully-equipped in-house gym facility, designed to make your fitness journey effortless.',
        ' Indulge in comfort and luxury with our spacious, fully-furnished rooms, providing a haven of relaxation and style within our coliving community.'];

    const propertySlug = propertyDetails[0].building_slug;

    const handleSlideChange = (swiper) => {
        setActiveAmenitiesSlideIndex(swiper.activeIndex);

        switch (swiper.activeIndex) {
            case 0:
                setAmenitiesBackgroundImage("images/landing/bg-cafeteria-image.png");
                break;
            case 1:
                setAmenitiesBackgroundImage("images/landing/bg-gym-image.png");
                break;
            case 2:
                setAmenitiesBackgroundImage("images/landing/bg-luxury-rooms.png");
                break;
            default:
                setAmenitiesBackgroundImage("images/landing/bg-cafeteria-image.png");
        }
    }

    const handleMobileSlideChange = (swiper) => {
        setActiveMobileAmenitiesSlideIndex(swiper.activeIndex);
        switch (swiper.activeIndex) {
            case 0:
                setAmenitiesBackgroundMobileImage("images/landing/bg-cafeteria-image.png");
                break;
            case 1:
                setAmenitiesBackgroundMobileImage("images/landing/bg-gym-image.png");
                break;
            case 2:
                setAmenitiesBackgroundMobileImage("images/landing/bg-luxury-rooms.png");
                break;
            default:
                setAmenitiesBackgroundMobileImage("images/landing/bg-cafeteria-image.png");
        }
    }

    const [swiper, setReviewSwiper] = useState(null);


    const goReviewNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goReviewPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    useEffect(() => {
        AOS.init({duration: 1200});
    }, []);

    useEffect(() => {
        console.log(activePropertyTab)
    }, [activePropertyTab])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">

                    {/* navbar section */}
                    <div className='row justify-content-center fixed-top py-4 scroll-gradient' data-aos="fade-down"
                         data-aos-easing="ease-in-out">
                        <div className={'col-md-10  col-11'}>
                            {['sm'].map((expand) => (
                                <Navbar key={expand} expand={expand}
                                        className="bg-body-tertiary bg-nav-bar-container mb-3">
                                    <Container fluid className={"row"}>
                                        <Navbar.Brand className={'col-6'}>
                                            <div className={"row"}>
                                                <div className="col-md-10 col-12 d-flex justify-content-center">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-2 col-5 pe-0 ">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-3 col-7 pe-0 ">
                                                            <div className={'w-100 text-wrap'}>
                                                                <label
                                                                    className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                    Bestow Living</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle className={'col-2 border-0'}
                                                       aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                        <Navbar.Offcanvas
                                            className={'col-12'}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="top"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                    <div className={'row'}>
                                                        <div className="col-5 d-flex justify-content-start">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-5 col-5 ps-0">
                                                                    <Link to={"/"}>
                                                                        <img className="img-fluid"
                                                                             src={"images/landing/co-live-logo-image.png"}
                                                                             alt="logo"/>
                                                                    </Link>
                                                                </div>
                                                                <div className="col-7 p-0">
                                                                    <label
                                                                        className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                        Bestow Living</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav
                                                    className="d-md-flex gap-3 justify-content-md-end align-items-center font-family-playfair flex-grow-1 pe-0">
                                                    <Nav>
                                                        <div>
                                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">Explore
                                                                    properties
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/about"}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">About
                                                                    us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/contactus"}>
                                                                <button
                                                                    className="btn-bg btn-contact-us font-size-14 font-weight-500 font-color-neutral-white"
                                                                    type="button">Contact us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>
                            ))}
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 my-4"></div>
                            <div className="py-4 py-md-2"></div>
                        </div>
                    </div>

                    {/* hero section */}
                    <div className="row py-2">
                        <div className="col-12 py-4 my-md-4">
                            <div className="row justify-content-md-around mx-2">
                                <div className={'col-1'}></div>
                                <div className="col-12 col-md-5" data-aos="fade" data-aos-delay="1000"
                                     data-aos-easing="ease-in-sine" data-aos-duration="600">
                                    <div className="row">
                                        <div className="col-12 col-md-10">
                                            <div
                                                className="row d-flex flex-row align-items-center bg-embrace-community-container px-3 py-2 br-40">
                                                <div className="col-2">
                                                    <img className="img-fluid" src={"images/landing/home-image.gif"}
                                                         alt="home gif"/>
                                                </div>
                                                <div className="col-10 p-0">
                                                    <label
                                                        className="font-size-18 font-weight-500 font-color-theme-primary1 font-family-inter ">
                                                        Embrace Community, Elevate Living
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="pt-md-4 mt-3">
                                                <label
                                                    className="font-size-64 font-weight-700 font-color-theme-primary1">
                                                    <span className="font-color-theme-primary2">Bestow Living </span>
                                                    More Than Just A Place To Stay
                                                </label>
                                            </div>
                                            <div className="pt-3">
                                                <label
                                                    className="font-size-18 font-weight-400 font-color-theme-primary1 font-family-inter">
                                                    Unlock a vibrant and inclusive lifestyle at our fully furnished
                                                    luxury co-living apartments,
                                                    where community meets comfort. Upgrade your lifestyle with modern
                                                    amenities and experience
                                                    the home vibe away from your home with delicious home-cooked food
                                                    under one roof.
                                                    In a nutshell, Le Bestow Living means luxury, location, and
                                                    convenience.
                                                    Finally, this is what you’ve been searching for.
                                                </label>
                                            </div>
                                            <div className="pt-4 mt-md-3">
                                                <Link to={"/contactus"}>
                                                    <button type="button"
                                                            className="btn-bg btn-contact-us-hero-section font-family-inter font-color-neutral-white font-size-14 font-weight-500">Contact
                                                        us
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 bg-hero-home-image-container d-none d-md-block"
                                     data-aos="fade" data-aos-duration="500" data-aos-delay="2000">
                                    <div className="row d-flex justify-content-between align-items-center px-4">
                                        <div className="col-12 d-flex justify-content-end pb-4">
                                            <div className="d-flex justify-content-around">
                                                <div className="hero-image pe-5 ">
                                                    <img className="img-fluid home-images-bg-shadow rounded-circle"
                                                         src={"images/landing/delicious-food.png"}
                                                         alt="delicious food"/>
                                                </div>
                                                <div className="align-self-end button-image-animation">
                                                    <div className="pb-2">
                                                        <img className="img-fluid"
                                                             src={"images/landing/left-navigation.png"}
                                                             alt="left arrow"/>
                                                    </div>
                                                    <button type="button"
                                                            className="font-family-inter font-size-14 font-weight-500 font-color-theme-primary2 btn-bg-hero-image">Delicious
                                                        food
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 d-flex justify-content-start align-items-center ps-0 pb-4">
                                            <div className="hero-image pe-5">
                                                <img className="img-fluid home-images-bg-shadow rounded-circle"
                                                     src={"images/landing/best-accommodation.png"} alt="accommodation"/>
                                            </div>
                                            <div className="button-image-animation ps-2">
                                                <div className="pb-2">
                                                    <img className="img-fluid"
                                                         src={"images/landing/left-navigation.png"} alt="left arrow"/>
                                                </div>
                                                <button type="button"
                                                        className="font-family-inter font-size-14 font-weight-500 font-color-theme-primary2 btn-bg-hero-image">Best
                                                    accommodation
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-end">
                                            <div className="align-self-end button-image-animation pe-4">
                                                <div className="pb-2 d-flex justify-content-end">
                                                    <img className="img-fluid"
                                                         src={"images/landing/right-gps-navigation.png"}
                                                         alt="right arrow"/>
                                                </div>
                                                <button type="button"
                                                        className="font-family-inter font-size-14 font-weight-500 font-color-theme-primary2 btn-bg-hero-image">Vibrant
                                                    community
                                                </button>
                                            </div>
                                            <div className="hero-image ps-5">
                                                <img className="img-fluid home-images-bg-shadow rounded-circle"
                                                     src={"images/landing/friendly-environment.png"}
                                                     alt="friendly environment"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Why Choose Us */}
                    <div className="row py-2 mx-3">
                        <div className="col-12 py-4 my-md-4 px-0 justify-content-center">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 justify-content-center">
                                    <div className="text-center" data-aos="fade" data-aos-delay="100">
                                        <label className="font-size-42 font-weight-700 font-color-grey-900">Why Choose
                                            Us</label>
                                    </div>
                                    <div className="row py-md-4 mt-4 mt-md-3">
                                        <div className="col-12 col-md-4 why-choose-us-container">
                                            <div className="pb-3" data-aos="fade-up" data-aos-delay="200">
                                                <img className="img-fluid" src={"images/landing/avatars-image.png"}
                                                     alt="avatars image"/>
                                            </div>
                                            <div className="body-content font-family-inter">
                                                <div data-aos="fade-up" data-aos-delay="300">
                                                    <label className="font-size-18 font-weight-500 font-color-grey-900">
                                                        Community Beyond Walls
                                                    </label>
                                                </div>
                                                <div className="pt-2" data-aos="fade-up" data-aos-delay="600">
                                                    <label className="font-size-14 font-weight-400 font-color-grey-500">
                                                        Choose us for a co-living experience where we make strong
                                                        friendships in a lively community, not just sharing rooms.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 why-choose-us-container mt-3 mt-md-0">
                                            <div className="pb-3" data-aos="fade-up" data-aos-delay="1100">
                                                <img className="img-fluid" src={"images/landing/finger-show-image.png"}
                                                     alt="finger show image"/>
                                            </div>
                                            <div className="font-family-inter">
                                                <div data-aos="fade-up" data-aos-delay="1400">
                                                    <label className="font-size-18 font-weight-500 font-color-grey-900">
                                                        Tailored For You
                                                    </label>
                                                </div>
                                                <div className="pt-2" data-aos="fade-up" data-aos-delay="1500">
                                                    <label className="font-size-14 font-weight-400 font-color-grey-500">
                                                        Discover personalised co-living, where your preferences
                                                        shape your space, creating a home away from your home.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 why-choose-us-container mt-3 mt-md-0">
                                            <div className="pb-3" data-aos="fade-up" data-aos-delay="2100">
                                                <img className="img-fluid" src={"images/landing/fan-image.png"}
                                                     alt="fan image"/>
                                            </div>
                                            <div className="font-family-inter">
                                                <div data-aos="fade-up" data-aos-delay="2400">
                                                    <label className="font-size-18 font-weight-500 font-color-grey-900">
                                                        Exceptional Amenities
                                                    </label>
                                                </div>
                                                <div className="pt-2" data-aos="fade-up" data-aos-delay="2500">
                                                    <label
                                                        className="font-size-14 font-weight-400 font-color-grey-500 font-family-inter">
                                                        Elevate your lifestyle with an array of modern amenities.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Amenities Container Website */}
                    <div className={`row bg-amenities-container d-none d-md-block`} id="bg-amenities-container-website"
                         style={{backgroundImage: `url(${amenitiesBgImage}), url(${backgroundAmenitiesImage})`}}>
                        <div className="col-12 py-md-4 overflow-hidden">
                            <div className="row py-4 mx-4">
                                <div className="col-12 col-md-12 py-md-4">
                                    <div className="row py-md-2 mx-4">
                                        <div className="col-12 website-amenities-container">
                                            <div>
                                                <label
                                                    className="font-size-64 font-weight-600 font-color-neutral-white "
                                                    id="slide-heading">{amenitiesHeading[activeAmenitiesSlideIndex]}</label>
                                                <div className="pt-2">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-lightest font-family-inter"
                                                        id="slide-description">
                                                        {amenitiesDesc[activeAmenitiesSlideIndex]}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mx-md-5">
                                                <Swiper
                                                    className="overflow-visible"
                                                    parallax={true}
                                                    spaceBetween={50}
                                                    slidesPerView={1}

                                                    speed={1000}
                                                    navigation={false}
                                                    freeMode={true}
                                                    modules={[Mousewheel]}
                                                    mousewheel={{
                                                        releaseOnEdges: true,
                                                        eventsTarged: '.website-amenities-container' // target the swiper container for mousewheel events
                                                    }}
                                                    onSlideChange={handleSlideChange}
                                                    onSwiper={(swiper) => console.log(swiper)}>
                                                    <SwiperSlide>
                                                        <div className={"pt-4 mt-md-3"}>
                                                            <div
                                                                className="position-relative amenities-animation-container">
                                                                <img
                                                                    className="img-fluid background-image  position-absolute rotate-in-left"
                                                                    src={"images/landing/cafeteria-with-round-tables-and-chairs-image.png"}
                                                                    alt={"cafeteria round table chair image"}/>
                                                                <img className="img-fluid br-20"
                                                                     src={"images/landing/cafeteria-image.png"}
                                                                     alt={"cafeteria image"}/>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className={"pt-4 mt-md-3"}>
                                                            <div
                                                                className="position-relative amenities-animation-container">
                                                                <img
                                                                    className="img-fluid background-image position-absolute rotate-in-left"
                                                                    src={"images/landing/gym-image.png"}
                                                                    alt="gym image"/>
                                                                <img className="img-fluid"
                                                                     src={"images/landing/gym-display-image.png"}
                                                                     alt="gym image"/>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className={"pt-4 mt-md-3"}>
                                                            <div
                                                                className="position-relative amenities-animation-container">
                                                                <img
                                                                    className="img-fluid background-image position-absolute rotate-in-left br-20"
                                                                    src={"images/landing/cabboards-and-beds-image.png"}
                                                                    alt="wooden bed image"/>
                                                                <img className="img-fluid br-20"
                                                                     src={"images/landing/beds-and-cabords-image-.png"}
                                                                     alt="beds-and-cabords-image-.png"/>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Amenities Container Mobile */}
                    <div className="row bg-amenities-container d-md-none d-block" id="bg-amenities-container"
                         style={{backgroundImage: `url(${amenitiesBgImage}), url(${backgroundAmenitiesMobileImage})`}}>
                        <div className="col-12 py-4 overflow-hidden px-4">
                            <Swiper
                                className="overflow-visible py-4"
                                spaceBetween={50}
                                slidesPerView={1}
                                autoplay={true}
                                speed={1000}
                                modules={[Autoplay]}
                                onSlideChange={handleMobileSlideChange}
                                onSwiper={(swiper) => console.log(swiper)}>
                                <SwiperSlide>
                                    <div>
                                        <label
                                            className="font-size-64 font-weight-600 font-color-neutral-white">Cafeteria</label>
                                        <div className="pt-2">
                                            <label
                                                className="font-size-14 font-weight-500 font-color-neutral-grey-lightest font-family-inter">
                                                Our cafeteria is a space where you can relax, socialise, and enjoy
                                                delicious meals
                                                along with a variety of healthy snacks under one roof.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="pt-4 mt-md-3 ">
                                        <div className="position-relative amenities-animation-container">
                                            <img
                                                className="img-fluid background-image  position-absolute rotate-in-left"
                                                src={"images/landing/cafeteria-with-round-tables-and-chairs-image.png"}
                                                alt={"cafeteria round table chair image"}/>
                                            <img className="img-fluid br-20 " src={"images/landing/cafeteria-image.png"}
                                                 alt={"cafeteria image"}/>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <label
                                            className="font-size-64 font-weight-600 font-color-neutral-white">Gym</label>
                                        <div className="p-2">
                                            <label
                                                className="font-size-14 font-weight-500 font-color-neutral-grey-lightest font-family-inter">
                                                Get fit and feel great with our fully-equipped in-house
                                                gym facility, designed to make your fitness journey effortless.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="pt-4 mt-md-3 ">
                                        <div className="position-relative amenities-animation-container">
                                            <img className="img-fluid background-image position-absolute rotate-in-left"
                                                 src={"images/landing/gym-image.png"} alt="gym image"/>
                                            <img className="img-fluid" src={"images/landing/gym-display-image.png"}
                                                 alt="gym image"/>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <label className="font-size-64 font-weight-600 font-color-neutral-white">Luxury
                                            Rooms</label>
                                        <div className="pt-2">
                                            <label
                                                className="font-size-14 font-weight-500 font-color-neutral-grey-lightest font-family-inter">
                                                Indulge in comfort and luxury with our spacious, fully-furnished rooms,
                                                providing a haven of relaxation and style within our coliving community.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="pt-4 mt-md-3 ">
                                        <div className="position-relative amenities-animation-container">
                                            <img
                                                className="img-fluid background-image position-absolute rotate-in-left br-20"
                                                src={"images/landing/cabboards-and-beds-image.png"}
                                                alt="wooden bed image"/>
                                            <img className="img-fluid br-20"
                                                 src={"images/landing/beds-and-cabords-image-.png"}
                                                 alt="beds-and-cabords-image-.png"/>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    {/* Our Properties */}
                    <div className="row bg-color-light-dark py-2">
                        <div className="col-12 py-4 my-md-4 p-0">
                            <div className="row mx-3">
                                <div className="col-1"></div>
                                <div className="col-12 col-md-11 p-0">
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-12 col-md-5" data-aos="fade" data-aos-duration="400">
                                            <div className="heading-container">
                                                <label
                                                    className="font-size-14 font-weight-500 font-family-inter font-color-neutral-grey-light">FIND
                                                    YOUR PERFECT MATCH</label>
                                                <div className="pt-1">
                                                    <label
                                                        className="font-size-42 font-weight-600 font-color-neutral-grey-darkest">
                                                        Our Properties
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 pe-md-0">
                                            <div className="pt-4 mt-md-3 w-100">
                                                <Tab.Container id="left-tabs-example"
                                                               defaultActiveKey={activePropertyTab}>
                                                    <Row className="d-md-flex justify-content-md-between">
                                                        <Col sm={12} md={5} className={'property-tabs-container'}>
                                                            <Nav variant="pills" className="flex-column gap-3">
                                                                {propertyDetails.map(each => (
                                                                    <Nav.Item key={each.id}>
                                                                        <Nav.Link eventKey={each.id}
                                                                                  className={`${activePropertyTab === `${each.id}` ? 'active-property-tab' : 'non-active-property-tab'}`}
                                                                                  onClick={() => setActivePropertyTab(each.id)}>
                                                                            <div
                                                                                className="row d-flex justify-content-around py-3"
                                                                                data-aos="fade" data-aos-duration="400">
                                                                                <div className="col-4 ps-0">
                                                                                    <img
                                                                                        className="img-fluid w-100 h-100 br-5"
                                                                                        src={each.property_image[0].image_url}
                                                                                        alt={each.property_image[0].alt}/>
                                                                                </div>
                                                                                <div
                                                                                    className="col-7 content-container font-family-inter p-0">
                                                                                    <div
                                                                                        className="d-flex flex-column gap-md-2 gap-1">
                                                                                        <label
                                                                                            className="font-size-18 font-weight-500 font-color-neutral-grey-darkest">{each.building_name}</label>
                                                                                        <div
                                                                                            className="d-flex flex-row align-items-center gap-2">
                                                                                            <div>
                                                                                                <img
                                                                                                    className="img-fluid"
                                                                                                    src={"images/landing/map-location-icon.png"}
                                                                                                    alt="map icon"/>
                                                                                            </div>
                                                                                            <label
                                                                                                className="font-size-14 font-weight-400 font-color-neutral-grey-dark">{each.address}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="pt-md-3 pt-2">
                                                                                        <label
                                                                                            className="font-size-14 font-weight-500 font-color-neutral-grey-darkest ">
                                                                                            <span
                                                                                                className="font-weight-400 font-color-neutral-grey-dark">Starting from: </span>
                                                                                            Rs {each.starting_from}/ Bed
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="pt-md-3 pt-2">
                                                                                        <Link
                                                                                            to={`/exploreproperties/${each.building_slug}`}>
                                                                                            <button type="button"
                                                                                                    className="btn-bg btn-see-details font-size-14 font-weight-500 font-color-neutral-white">See
                                                                                                details
                                                                                            </button>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))}
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={"gaming"}
                                                                              className={`${activePropertyTab === "gaming" ? 'active-property-tab' : 'non-active-property-tab'}`}
                                                                              onClick={() => setActivePropertyTab("gaming")}>
                                                                        <div
                                                                            className="row d-flex justify-content-around py-3">
                                                                            <div className="col-4 ps-0">
                                                                                <img
                                                                                    className="img-fluid w-100 h-100 br-5"
                                                                                    src={"/images/gaming/gaming-property-image.png"}
                                                                                    alt={"gaming property image"}/>
                                                                            </div>
                                                                            <div
                                                                                className="col-7 content-container font-family-inter p-0">
                                                                                <div
                                                                                    className="d-flex flex-column gap-md-2 gap-1">
                                                                                    <label
                                                                                        className="font-size-18 font-weight-500 font-color-neutral-grey-darkest">Le
                                                                                        Bestow Gaming</label>
                                                                                    <div
                                                                                        className="d-flex flex-row align-items-center gap-2">
                                                                                        <div>
                                                                                            <img className="img-fluid"
                                                                                                 src={"images/landing/map-location-icon.png"}
                                                                                                 alt="map icon"/>
                                                                                        </div>
                                                                                        <label
                                                                                            className="font-size-14 font-weight-400 font-color-neutral-grey-dark">HiTech
                                                                                            City, Hyderabad</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="pt-md-3 pt-2">
                                                                                    <label
                                                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-darkest ">
                                                                                        <span
                                                                                            className="font-weight-400 font-color-neutral-grey-dark">Starting from: </span>
                                                                                        Rs 300/Hour
                                                                                    </label>
                                                                                </div>
                                                                                <div className="pt-md-3 pt-2">
                                                                                    <Link to={'/gaming'}>
                                                                                        <button type="button"
                                                                                                className="btn-bg btn-see-details font-size-14 font-weight-500 font-color-neutral-white">See
                                                                                            details
                                                                                        </button>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey={"cafe"}
                                                                              className={`${activePropertyTab === "cafe" ? 'active-property-tab' : 'non-active-property-tab'}`}
                                                                              onClick={() => setActivePropertyTab("cafe")}>
                                                                        <div
                                                                            className="row d-flex justify-content-around py-3">
                                                                            <div className="col-4 ps-0">
                                                                                <img
                                                                                    className="img-fluid w-100 h-100 br-5"
                                                                                    src={"/images/cafe/cafe-property-image.png"}
                                                                                    alt={"cafe property image"}/>
                                                                            </div>
                                                                            <div
                                                                                className="col-7 content-container font-family-inter p-0">
                                                                                <div
                                                                                    className="d-flex flex-column gap-md-2 gap-1">
                                                                                    <label
                                                                                        className="font-size-18 font-weight-500 font-color-neutral-grey-darkest">Le
                                                                                        Bestow Cafe</label>
                                                                                    <div
                                                                                        className="d-flex flex-row align-items-center gap-2">
                                                                                        <div>
                                                                                            <img className="img-fluid"
                                                                                                 src={"images/landing/map-location-icon.png"}
                                                                                                 alt="map icon"/>
                                                                                        </div>
                                                                                        <label
                                                                                            className="font-size-14 font-weight-400 font-color-neutral-grey-dark">Madhapur,
                                                                                            Hyderabad</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="pt-md-3 pt-2">
                                                                                    <label
                                                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-darkest ">
                                                                                        <span
                                                                                            className="font-weight-400 font-color-neutral-grey-dark">Starting from: </span>
                                                                                        Rs 300
                                                                                    </label>
                                                                                </div>
                                                                                <div className="pt-md-3 pt-2">
                                                                                    <Link to={'/cafe'}>
                                                                                        <button type="button"
                                                                                                className="btn-bg btn-see-details font-size-14 font-weight-500 font-color-neutral-white">See
                                                                                            details
                                                                                        </button>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </Col>
                                                        <Col sm={12} md={6}
                                                             className="p-md-0 pt-4 d-none d-md-block pt-md-0"
                                                             data-aos-duration="1000" data-aos-delay="1100"
                                                             data-aos="fade-left">
                                                            <Tab.Content>
                                                                {propertyDetails.map(each => (
                                                                    <Tab.Pane eventKey={each.id} key={each.id}>
                                                                        <Swiper
                                                                            className="overflow-hidden aira-property-swiper"
                                                                            spaceBetween={10}
                                                                            slidesPerView={1}
                                                                            loop={true}
                                                                            pagination={true}
                                                                            navigation={true}
                                                                            autoplay={true}
                                                                            speed={1000}
                                                                            modules={[Autoplay, Pagination, Navigation]}
                                                                            onSwiper={(swiper) => console.log(swiper)}
                                                                        >
                                                                            {each.slider_images.map((eachImage, index) => (
                                                                                <SwiperSlide key={index}>
                                                                                    <img src={eachImage.image}
                                                                                         className="d-block w-100 rounded-start-3"
                                                                                         alt={eachImage.alt}/>
                                                                                </SwiperSlide>
                                                                            ))}
                                                                        </Swiper>
                                                                    </Tab.Pane>
                                                                ))}
                                                                <Tab.Pane eventKey={"gaming"}>
                                                                    <Swiper
                                                                        className="overflow-hidden aira-property-swiper"
                                                                        spaceBetween={10}
                                                                        slidesPerView={1}
                                                                        loop={true}
                                                                        pagination={true}
                                                                        navigation={true}
                                                                        autoplay={true}
                                                                        speed={1000}
                                                                        modules={[Autoplay, Pagination, Navigation]}
                                                                        onSwiper={(swiper) => console.log(swiper)}
                                                                    >
                                                                        {gameData.map((eachGame) => (
                                                                            eachGame.slider_images.map((gameImage, index) => (
                                                                                <SwiperSlide key={index}>
                                                                                    <img
                                                                                        className="d-block w-100 h-100 rounded-start-3"
                                                                                        src={gameImage.image}
                                                                                        alt={gameImage.alt}/>
                                                                                </SwiperSlide>
                                                                            ))
                                                                        ))}
                                                                    </Swiper>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey={"cafe"}>
                                                                    <Swiper
                                                                        className="overflow-hidden aira-property-swiper"
                                                                        spaceBetween={10}
                                                                        slidesPerView={1}
                                                                        loop={true}
                                                                        pagination={true}
                                                                        navigation={true}
                                                                        autoplay={true}
                                                                        speed={1000}
                                                                        modules={[Autoplay, Pagination, Navigation]}
                                                                        onSwiper={(swiper) => console.log(swiper)}
                                                                    >
                                                                        {cafeData.map((eachCafe) => (
                                                                            eachCafe.slider_images.map((cafeImage, index) => (
                                                                                <SwiperSlide key={index}>
                                                                                    <img
                                                                                        className="d-block w-100 h-100 rounded-start-3"
                                                                                        src={cafeImage.image}
                                                                                        alt={cafeImage.alt}/>
                                                                                </SwiperSlide>
                                                                            ))
                                                                        ))}
                                                                    </Swiper>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Secure Your Spot: Claim Your Cozy Hostel Retreat Today! */}
                    <div className="row bg-secure-your-spot-container bg-secure-your-spot-gradient">
                        <div className="col-12 py-4 my-md-4">
                            <div className="row py-2 justify-content-center" data-aos="fade" data-aos-duration="1000"
                                 data-aos-easing="ease in">
                                <div className="col-10 bg-color-light-dark br-20">
                                    <div className="row d-flex justify-content-between p-md-4 m-md-3 p-3">
                                        <div className="col-12 col-md-6">
                                            <div className="d-md-flex flex-column gap-4 h-100 d-none">
                                                <div className="h-100">
                                                    <img className="img-fluid w-100 h-100 images-bg-shadow br-10"
                                                         src={"images/landing/three-beds-and-caboards-image.png"}
                                                         alt="round table chairs image"/>
                                                </div>
                                                <div className="h-100">
                                                    <img className="img-fluid w-100 h-100 images-bg-shadow br-10"
                                                         src={"images/landing/wooden-sofa-chair-image.png"}
                                                         alt="wooden sofa chair image"/>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div
                                                className="bg-secure-your-spot-content-container br-10 h-100 px-md-4 px-3 py-md-4 py-3">
                                                <div className="w-100" data-aos="fade-right">
                                                    <label
                                                        className="font-size-42 font-weight-600 text-gradient-secure-spot">
                                                        Secure Your Spot : Colive in style, make memories that last
                                                        forever.
                                                    </label>

                                                    <div className="pt-md-4 mt-md-3 pt-3">
                                                        <Link to={"/contactus"}>
                                                            <button type="button"
                                                                    className="btn-bg btn-see-details font-family-inter font-size-18 font-weight-500 font-color-neutral-white">
                                                                Contact us
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* review container */}
                    <div className="row bg-happy-users-container">
                        <div className="col-12 py-4 my-md-4">
                            <div className="row py-2 mx-3 mx-md-0">
                                <div className="col-1"></div>
                                <div className="col-12 col-md-11 p-0">
                                    <div className="row d-flex flex-column align-items-start">
                                        <div className="col-10 d-block d-md-flex justify-content-md-between">
                                            <div>
                                                <label className="font-size-64 font-weight-600 font-color-grey-900">
                                                    <span
                                                        className=" font-weight-700 font-color-theme-primary2">1500+</span> Happy
                                                    Users
                                                </label>
                                                <div>
                                                    <label
                                                        className="font-size-18 font-weight-500 font-color-neutral-grey-light font-family-inter">Real
                                                        Stories, Unforgettable Memories</label>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex align-self-md-end justify-content-start pt-md-0 pt-3 gap-4">
                                                <div>
                                                    <button type="button" onClick={goReviewPrev}
                                                            className=" p-1 p-md-2 bg-color-orange-50 btn-border-color-theme-primary-2"
                                                            id="review-left-arrow">
                                                        <Icon.ChevronLeft className="font-color-theme-primary2"/>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button type="button" onClick={goReviewNext}
                                                            className="p-1 p-md-2 bg-color-orange-50 btn-border-color-theme-primary-2"
                                                            id="review-right-arrow">
                                                        <Icon.ChevronRight className="font-color-theme-primary2"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 py-md-4 w-100">
                                    <Swiper
                                        className="review-swiper py-4"
                                        spaceBetween={20}
                                        slidesPerView={3}
                                        loop={true}
                                        autoplay={true}
                                        speed={1000}
                                        modules={[Autoplay]}
                                        breakpoints={
                                            {
                                                600: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 20,
                                                },

                                                300: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 20,
                                                    coverflowEffect: {
                                                        depth: 300
                                                    }
                                                },
                                                1024: {
                                                    slidesPerView: 2,
                                                },
                                            }
                                        }
                                        onSwiper={setReviewSwiper}
                                    >
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body p-2 font-family-inter d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        My experience staying at Le Bestow Living has been nothing short
                                                        of excellent.
                                                        The room came with all the essential facilities, including a
                                                        comfortable bed, a well-equipped common gym ,and a clean
                                                        bathroom.
                                                        Overall, it's been a hassle-free and affordable living
                                                        arrangement that I highly recommend.
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/akshay-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Akshay
                                                                    Kumar</label>
                                                                <div className="">
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Kondapur,
                                                                        Hyderabad</label>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body font-family-inter p-2 d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        I was searching Co-living spaces near Hyderabad and very happy
                                                        that time
                                                        I found Le Bestow Living. Living at Le Bestow Living has been a
                                                        transformative
                                                        experience! The vibrant community, modern amenities, and
                                                        seamless co-living
                                                        concept have enriched my social life, making every day an
                                                        adventure. Truly grateful!
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/naveen-kumar-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Naveen
                                                                    Kumar</label>
                                                                <div>
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Hi-Tech
                                                                        City, Hyderabad</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body font-family-inter p-2 d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        While searching for a good place to stay, I was afraid to get
                                                        all the facilities like cozy rooms ,
                                                        healthy food and a friendly community or not. Choosing Le Bestow
                                                        Living was the best decision ever!
                                                        The dynamic community, gym facilities , and healthy food
                                                        collaborative atmosphere really surprised me.
                                                        Living here feels like being part of an inspiring family!
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/akshay-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Kiran</label>
                                                                <div>
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Kondapur,
                                                                        Hyderabad</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body font-family-inter p-2 d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        I was worried about my personal space before thinking of staying
                                                        at Le Bestow Living, but my opinion changed with days passing
                                                        through.
                                                        The friendly community created an atmosphere where I started
                                                        feeling like my second home. I love it.
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/naveen-kumar-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Rakesh</label>
                                                                <div>
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Hi-Tech
                                                                        City, Hyderabad</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body font-family-inter p-2 d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        I was searching for a nice place near my office to stay.Then
                                                        fortunately I bumped into this place.
                                                        Le Bestow Living offers awesome living with all modern
                                                        facilities such as a cozy cafeteria, luxury rooms, a fully
                                                        equipped gym and many more.
                                                        The cleaning department is too good. In a nutshell, It’s worth
                                                        spending money on living here.
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/akshay-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Sai</label>
                                                                <div>
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Kondapur,
                                                                        Hyderabad</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body font-family-inter p-2 d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        Vey nice and peaceful place to stay.
                                                        Till date my experience has just been wow.
                                                        All in all it's a total package at a budget friendly cost.
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/ramya-pandian-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Chandini</label>
                                                                <div>
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Hi-Tech
                                                                        City, Hyderabad</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body font-family-inter p-2 d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        Pleasant atmosphere with all modern facilities and pocket
                                                        friendly. The entire staff is too good. My dealings with them
                                                        have always left me feeling that the situation was resolved both
                                                        in a timely and fair manner.
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/ramya-pandian-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Rashmika</label>
                                                                <div>
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Kondapur,
                                                                        Hyderabad</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div
                                                className="card bg-white p-4 br-10 review-container-box-shadow border-0 h-100">
                                                <div
                                                    className="card-body font-family-inter p-2 d-flex flex-column justify-content-between">
                                                    <label
                                                        className="font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                        I absolutely love the environment! In one line’ East or west, Le
                                                        Bestow Living’ is the best. So, thank you very very much for
                                                        making my life so much more enjoyable.
                                                    </label>
                                                    <div className="pt-3">
                                                        <div
                                                            className="d-flex justify-content-start gap-2 align-items-center">
                                                            <div>
                                                                <img className="img-fluid rounded-circle"
                                                                     src={"images/landing/ramya-pandian-profile-image.png"}
                                                                     alt="profile image"/>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    className="font-weight-500 font-size-14 font-color-neutral-grey-darkest">Lipsa</label>
                                                                <div>
                                                                    <label
                                                                        className="font-weight-400 font-size-12 font-color-neutral-grey-light">Hi-Tech
                                                                        City, Hyderabad</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  footer section  */}
                    <div className="row bg-footer-color justify-content-center">
                        <div className="col-12 col-md-10 py-4 my-4">
                            <div className="row font-color-neutral-white font-family-inter">
                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 col-md-10">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-4 col-3">
                                                    <Link to={"/"}>
                                                        <img className="img-fluid"
                                                             src={"/images/landing/co-live-logo-image.png"} alt="logo"/>
                                                    </Link>
                                                </div>
                                                <div className="col-md-4 col-3 ps-0 p-md-0">
                                                    <label
                                                        className="font-size-14 font-weight-600 font-color-theme-primary2 font-family-inter">Le
                                                        Bestow Living</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="pt-4">
                                                <label
                                                    className="font-size-14 font-weight-400 font-color-neutral-grey-lightest">©
                                                    2024 Bestow. All rights reserved.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="row ">
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Company
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={`/exploreproperties/${propertySlug}`}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Explore
                                                            properties
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/about"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">About
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Contact
                                                            us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-4 px-md-0 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Support
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={""}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Help
                                                            center
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={""}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Cancellation
                                                            policy
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={'/termsandconditions'}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Terms
                                                            and conditions
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Contact
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Phone className="text-white"/>
                                                    </div>
                                                    <label
                                                        className="font-size-14 font-weight-400">+91-9100009059</label>
                                                </div>
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Mail className="text-white"/>
                                                    </div>
                                                    <div>
                                                        <a href="mailto:sales.bestowliving@gmail.com"
                                                           className="font-size-14 font-color-neutral-white font-weight-400 text-decoration-none"> sales.bestowliving@gmail.com
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default HomePage;
