import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import * as Icon from 'react-feather';
import propertiesList from "../Models/propertyDetails.json";

const About = () => {
    const [activeTeamMemberIndex, setActiveTeamMemberIndex] = useState(0);

    const [swiper, setActiveTeamMemberSwiper] = useState(null);

    const propertySlug = propertiesList[0].building_slug;

    const goTeamMemberNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goTeamMemberPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {/*  navbar  /*}*/}
                    <div className=' row justify-content-center fixed-top py-4'>
                        <div className={'col-md-10 col-11'}>
                            {['sm'].map((expand) => (
                                <Navbar key={expand} expand={expand}
                                        className="bg-body-tertiary bg-nav-bar-container mb-3">
                                    <Container fluid className={"row"}>
                                        <Navbar.Brand className={'col-6'}>
                                            <div className={"row"}>
                                                <div className="col-md-10 col-12 d-flex justify-content-center">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-2 col-5 pe-0 ">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-3 col-7 pe-0">
                                                            <div className={'w-100 text-wrap'}>
                                                                <label
                                                                    className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">
                                                                    Le Bestow Living</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle className={'col-2 border-0'}
                                                       aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                        <Navbar.Offcanvas
                                            className={'col-12'}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="top"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                    <div className={'row'}>
                                                        <div className="col-5 d-flex justify-content-start">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-5 col-5 ps-0">
                                                                    <Link to={"/"}>
                                                                        <img className="img-fluid"
                                                                             src={"images/landing/co-live-logo-image.png"}
                                                                             alt="logo"/>
                                                                    </Link>
                                                                </div>
                                                                <div className="col-7 p-0">
                                                                    <label
                                                                        className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">
                                                                        Le Bestow Living</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav
                                                    className="d-md-flex gap-3 justify-content-md-end align-items-center font-family-playfair flex-grow-1 pe-0">
                                                    <Nav>
                                                        <div>
                                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">Explore
                                                                    properties
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/about"}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">About
                                                                    us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/contactus"}>
                                                                <button
                                                                    className="btn-bg btn-contact-us font-size-14 font-weight-500 font-color-neutral-white"
                                                                    type="button">Contact us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>
                            ))}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 my-4"></div>
                            <div className="py-4 my-md-4"></div>
                        </div>
                    </div>

                    {/*  hero section  */}
                    <div className="row py-2 mx-2 mx-md-0">
                        <div className="col-12 py-md-4 my-4 px-md-3">
                            <div className="row justify-content-md-around justify-content-between">
                                <div className="col-3 col-md-2 p-0">
                                    <div className="">
                                        <img
                                            className="img-fluid w-100 image-negative-rotation br-5 about-images-box-shadow about-image-border"
                                            src={"images/about/about-hero-section-beds-image.png"}
                                            alt="bed room image"/>
                                    </div>
                                </div>
                                <div className="d-none d-md-block col-md-7">
                                    <div className="text-center">
                                        <label className="font-weight-700 font-size-64 font-color-theme-primary1">
                                            Embrace <span
                                            className="font-color-theme-primary2">Togetherness,</span> Unleash <span
                                            className="font-color-theme-primary2">Comfort</span>
                                        </label>
                                        <div className="pt-3 pb-4">
                                            <label
                                                className="font-weight-500 font-size-18 font-color-neutral-grey-light font-family-inter">
                                                Live a better life together-where privacy meets community
                                            </label>
                                        </div>
                                        <div className="pt-3">
                                            <Link to={"/contactus"}>
                                                <button type="button"
                                                        className="btn-bg btn-contact-us-hero-section font-family-inter font-size-18 font-weight-500 font-color-neutral-white">Contact
                                                    us
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 col-md-2 p-0">
                                    <div className="">
                                        <img
                                            className="img-fluid w-100 h-100 image-positive-rotation br-5 about-images-box-shadow about-image-border"
                                            src={"images/about/about-reception-image.png"} alt="about reception image"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-10 d-block d-md-none embrace-togetherness-container">
                                    <div className="text-center">
                                        <label className="font-weight-700 font-size-64 font-color-theme-primary1">
                                            Embrace <span
                                            className="font-color-theme-primary2">Togetherness,</span> Unleash <span
                                            className="font-color-theme-primary2">Comfort</span>
                                        </label>
                                        <div className="pt-3 pb-4">
                                            <label
                                                className="font-weight-500 font-size-18 font-color-neutral-grey-light font-family-inter">
                                                Welcome to a sanctuary crafted for couples – where privacy meets
                                                community
                                            </label>
                                        </div>
                                        <div className="pt-3">
                                            <Link to={"/contactus"}>
                                                <button type="button"
                                                        className="btn-bg btn-contact-us-hero-section font-family-inter font-size-18 font-weight-500 font-color-neutral-white">Contact
                                                    us
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row justify-content-md-center hero-bottom-images-container justify-content-between py-md-4 my-md-4">
                                <div className="col-4 col-md-3">
                                    <div>
                                        <img className="img-fluid" src={"images/about/girls-talking-image.png"}
                                             alt="girls talking image"/>
                                    </div>
                                </div>
                                <div className="col-4 col-md-3">
                                    <div>
                                        <img className="img-fluid"
                                             src={"images/about/boys-discussion-image-infront-laptop.png"}
                                             alt="boys discussion image infront laptop"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*   Stats sections   */}
                    <div className="row justify-content-center py-2 mx-2 mx-md-0">
                        <div className="col-12 col-md-10 py-4 my-md-4 px-0">
                            <div className="row justify-content-center">
                                <div className="col-6 col-md-3">
                                    <div className="bg-status-card-container br-10 h-100">
                                        <div className="row justify-content-center">
                                            <div className="col-6 p-0">
                                                <img className="img-fluid" src={"/images/about/room.gif"}
                                                     alt="room jif"/>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <label
                                                className="font-size-42 font-weight-700 text-gradient-status-session">250+</label>
                                            <div>
                                                <label
                                                    className="font-size-18 font-weight-500 font-color-grey-400 font-family-inter">Rooms
                                                    served</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className=" bg-status-card-container br-10 h-100">
                                        <div className="row justify-content-center">
                                            <div className="col-6 p-0">
                                                <img className="img-fluid" src={"/images/about/star.gif"}
                                                     alt="star jif"/>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <label
                                                className="font-size-42 font-weight-700 text-gradient-status-session">98%</label>
                                            <div>
                                                <label
                                                    className="font-size-18 font-weight-500 font-color-grey-400 font-family-inter">Satisfaction
                                                    rate</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 mt-3 mt-md-0">
                                    <div className=" bg-status-card-container br-10 h-100">
                                        <div className="row justify-content-center">
                                            <div className="col-6 p-0">
                                                <img className="img-fluid" src={"/images/about/location.gif"}
                                                     alt="location jif"/>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <label
                                                className="font-size-42 font-weight-700 text-gradient-status-session">3</label>
                                            <div>
                                                <label
                                                    className="font-size-18 font-weight-500 font-color-grey-400 font-family-inter">Locations</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 mt-3 mt-md-0">
                                    <div className=" bg-status-card-container br-10 h-100">
                                        <div className="row justify-content-center">
                                            <div className="col-6 p-0">
                                                <img className="img-fluid" src={"/images/about/globe.gif"}
                                                     alt="globe jif"/>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <label
                                                className="font-size-42 font-weight-700 text-gradient-status-session">24/7</label>
                                            <div>
                                                <label
                                                    className="font-size-18 font-weight-500 font-color-grey-400 font-family-inter">Support</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Our Values  */}
                    <div className="row justify-content-center py-2">
                        <div className="col-12 col-md-10 py-4 my-md-4">
                            <div className="row">
                                <div className="col-12 text-center pb-4 mb-md-3">
                                    <div className="">
                                        <label className="font-size-42 font-weight-700 font-color-neutral-grey-darkest">
                                            Our Values
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 px-0">
                                    <div
                                        className="row bg-our-values-container align-items-start rounded-3 mx-3 mx-md-0">
                                        <div className="col-12 col-md-6 p-0">
                                            <img className="img-fluid img-container w-100 our-values-image-radius"
                                                 src={"images/about/hand-shake-image.png"} alt="hand shake image"/>
                                        </div>
                                        <div
                                            className="col-12 col-md-6 d-flex flex-row align-items-center rounded-end-3 h-100">
                                            <div className="row d-flex align-items-start">
                                                <div className="col-3 pt-3">
                                                    <div className="w-100">
                                                        <img className="img-fluid w-100"
                                                             src={"images/about/one-image.png"} alt="one image"/>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="py-md-3"></div>
                                                    <div className="p-md-4 p-3">
                                                        <div className="pb-3">
                                                            <label
                                                                className="font-weight-600 font-size-32 font-color-grey-700">Integrity
                                                                & Transparency</label>
                                                        </div>
                                                        <label
                                                            className="font-weight-400 font-size-18 font-color-theme-primary1 font-family-inter">
                                                            Guided by unwavering principles, we prioritize honesty and
                                                            openness in all our interactions,
                                                            ensuring trust is the foundation of every co-living
                                                            experience.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 px-0">
                                    <div className="row my-4 bg-our-values-container rounded-3 mx-3 mx-md-0">
                                        <div
                                            className="col-12 col-md-6 d-flex flex-row align-items-center rounded-end-3 order-1 order-md-0">
                                            <div className="row d-flex align-items-start">
                                                <div className="col-3">
                                                    <div className="w-100">
                                                        <img className="img-fluid w-100"
                                                             src={"images/about/two-image.png"} alt="two image"/>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="py-md-2"></div>
                                                    <div className="p-md-4 p-3">
                                                        <div className="pb-3">
                                                            <label
                                                                className="font-weight-600 font-size-32 font-color-grey-700">Community-Centric
                                                                Approach</label>
                                                        </div>
                                                        <label
                                                            className="font-weight-400 font-size-18 font-color-theme-primary1 font-family-inter">
                                                            At the heart of our values is a commitment to building a
                                                            vibrant and supportive community.
                                                            We believe in the power of shared experiences, fostering
                                                            connections that last a lifetime.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 col-md-6 p-0">
                                            <img className="img-fluid img-container w-100 our-values-left-image-radius"
                                                 src={"images/about/our-values-people-laughing-image.png"}
                                                 alt="people laughing image"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 px-0">
                                    <div className="row bg-our-values-container rounded-3 mx-3 mx-md-0">
                                        <div className="col-12 col-md-6 p-0">
                                            <img className="img-fluid img-container w-100 our-values-image-radius"
                                                 src={"images/about/our-values-friends-group-image-laughing.png"}
                                                 alt="friends laughing image"/>
                                        </div>
                                        <div
                                            className="col-12 col-md-6 d-flex flex-row align-items-center rounded-end-3">
                                            <div className="row d-flex align-items-start p-0">
                                                <div className="col-3 py-0">
                                                    <div className="w-100">
                                                        <img className="img-fluid w-100"
                                                             src={"images/about/three-image.png"} alt="three image"/>
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="py-md-3"></div>
                                                    <div className="p-md-4 p-3">
                                                        <div className="pb-3">
                                                            <label
                                                                className="font-weight-600 font-size-32 font-color-grey-700">Diversity
                                                                & Inclusion</label>
                                                        </div>
                                                        <label
                                                            className="font-weight-400 font-size-18 font-color-theme-primary1 font-family-inter">
                                                            Celebrating differences and embracing diversity, we create
                                                            spaces where everyone feels welcome.
                                                            Your unique story enriches the tapestry of our co-living
                                                            community.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* meet our team */}
                    <div className="row justify-content-center py-4">
                        <div className="col-12 my-md-4 pt-2">
                            <div className="text-center">
                                <label className="font-size-42 font-weight-700 font-color-neutral-grey-darkest">
                                    Meet Our Team
                                </label>
                            </div>
                        </div>
                        <div className="col-12 pt-md-3 pt-4 pb-md-4 pb-2">
                            <Swiper
                                effect={"coverflow"}
                                loop={true}
                                spaceBetween={60}
                                grabCursor={true}
                                slidesPerView={2}
                                centeredSlides={true}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 300,
                                    modifier: 1,
                                    slideShadows: false,
                                }}
                                breakpoints={{
                                    600: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },

                                    400: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                        coverflowEffect: {
                                            depth: 300
                                        }
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                    },
                                }}
                                modules={[EffectCoverflow]}
                                onSwiper={setActiveTeamMemberSwiper}
                                onSlideChange={(swiper) => setActiveTeamMemberIndex(swiper.realIndex)}
                            >
                                <SwiperSlide>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div
                                            className={`${activeTeamMemberIndex === 0 ? ("team-member-image-is-active") : ("team-member-image")}`}>
                                            <img className="img-fluid"
                                                 src={"/images/about/team-member-image-with-orange-bg.png"}
                                                 alt="team member"/>
                                        </div>
                                        {activeTeamMemberIndex === 0 && (
                                            <div className="team-member-details text-center">
                                                <div>
                                                    <label
                                                        className="font-size-32 font-weight-700 font-color-theme-primary1">Tim
                                                        Seifert</label>
                                                </div>
                                                <label
                                                    className="font-size-18 font-weight-400 font-color-grey-400">Manager</label>
                                            </div>
                                        )}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div
                                            className={`${activeTeamMemberIndex === 1 ? ("team-member-image-is-active") : ("team-member-image")}`}>
                                            <img className="img-fluid"
                                                 src={"/images/about/team-member-image-with-orange-bg.png"}
                                                 alt="team member"/>
                                        </div>
                                        {activeTeamMemberIndex === 1 && (
                                            <div className="team-member-details  team-member-active text-center">
                                                <div>
                                                    <label
                                                        className="font-size-32 font-weight-700 font-color-theme-primary1">Tim
                                                        Seifert</label>
                                                </div>
                                                <label
                                                    className="font-size-18 font-weight-400 font-color-grey-400">Manager</label>
                                            </div>
                                        )}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div
                                            className={`${activeTeamMemberIndex === 2 ? ("team-member-image-is-active") : ("team-member-image")}`}>
                                            <img className="img-fluid"
                                                 src={"images/about/team-member-image-with-orange-bg.png"}
                                                 alt="team member"/>
                                        </div>
                                        {activeTeamMemberIndex === 2 && (
                                            <div className="team-member-details team-member-active text-center">
                                                <div>
                                                    <label
                                                        className="font-size-32 font-weight-700 font-color-theme-primary1">Tim
                                                        Seifert</label>
                                                </div>
                                                <label
                                                    className="font-size-18 font-weight-400 font-color-grey-400">Manager</label>
                                            </div>
                                        )}

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div
                                            className={`${activeTeamMemberIndex === 3 ? ("team-member-image-is-active") : ("team-member-image")}`}>
                                            <img className="img-fluid"
                                                 src={"/images/about/team-member-image-with-orange-bg.png"}
                                                 alt="team member"/>
                                        </div>
                                        {activeTeamMemberIndex === 3 && (
                                            <div className="team-member-details  team-member-active text-center">
                                                <div>
                                                    <label
                                                        className="font-size-32 font-weight-700 font-color-theme-primary1">Tim
                                                        Seifert</label>
                                                </div>
                                                <label
                                                    className="font-size-18 font-weight-400 font-color-grey-400">Manager</label>
                                            </div>
                                        )}
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <div className="d-flex justify-content-center pt-4">
                                <div className="me-4">
                                    <button type="button" onClick={goTeamMemberPrev}
                                            className="p-2 border-1 rounded-circle btn-border-color-theme-primary-2 bg-transparent "
                                            id={'btn-our-team-left-arrow'}>
                                        <Icon.ArrowLeft className="font-color-theme-primary2"/>
                                    </button>
                                </div>
                                <div className="me-4">
                                    <button type="button" onClick={goTeamMemberNext}
                                            className="p-2 border-1 rounded-circle btn-border-color-theme-primary-2 bg-transparent "
                                            id={'btn-our-team-right-arrow'}>
                                        <Icon.ArrowRight className="font-color-theme-primary2"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Book_an_appointment_section  */}
                    <div className="row bg-secure-your-spot-container bg-secure-your-spot-gradient">
                        <div className="col-12 py-4 mt-md-4">
                            <div className="row py-2 justify-content-center">
                                <div className="col-10 bg-secure-spot-container br-20 p-0">
                                    <div className="row d-flex align-items-start my-md-4 py-md-3">
                                        <div className="col-12 col-md-5 ps-md-4 ms-md-3 d-none d-md-block">
                                            <div className="row ">
                                                <div className="col-6 ">
                                                    <img className="img-fluid images-bg-shadow br-10"
                                                         src={"/images/about/bed-room-cabboards-image.png"}
                                                         alt="bed image"/>
                                                </div>
                                                <div className="col-6 mt-5 ">
                                                    <img className="img-fluid images-bg-shadow br-10"
                                                         src={"/images/about/cafteria-image.png"} alt="cafteria image"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mt-md-5 br-10">
                                            <div className="p-3">
                                                <label
                                                    className="font-size-42 font-weight-600 text-gradient-secure-spot">
                                                    Secure Your Spot: Colive in style, make memories that last forever.
                                                </label>

                                                <div className="pt-md-4 mt-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button"
                                                                className="btn-bg btn-see-details font-family-inter font-size-18 font-weight-500 font-color-neutral-white">
                                                            Contact us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*footer section*/}
                    <div className="row bg-footer-color justify-content-center">
                        <div className="col-12 col-md-10 py-4 my-4">
                            <div className="row font-color-neutral-white font-family-inter">
                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 col-md-10">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-4 col-3">
                                                    <Link to={"/"}>
                                                        <img className="img-fluid"
                                                             src={"./images/landing/co-live-logo-image.png"}
                                                             alt="logo"/>
                                                    </Link>
                                                </div>
                                                <div className="col-md-4 col-3 ps-0 p-md-0">
                                                    <label
                                                        className="font-size-14 font-weight-600 font-color-theme-primary2 font-family-inter">Le
                                                        Bestow Living</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="pt-4">
                                                <label
                                                    className="font-size-14 font-weight-400 font-color-neutral-grey-lightest">©
                                                    2024 Bestow. All rights reserved.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="row ">
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Company
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={`/exploreproperties/${propertySlug}`}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Explore
                                                            properties
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/about"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">About
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Contact
                                                            us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-4 px-md-0 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Support
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={""}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Help
                                                            center
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={""}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Cancellation
                                                            policy
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={'/termsandconditions'}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Terms
                                                            and conditions
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Contact
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Phone className="text-white"/>
                                                    </div>
                                                    <label
                                                        className="font-size-14 font-weight-400">+91-9100009059</label>
                                                </div>
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Mail className="text-white"/>
                                                    </div>
                                                    <div>
                                                        <a href="mailto:sales.bestowliving@gmail.com"
                                                           className="font-size-14 font-color-neutral-white font-weight-400 text-decoration-none"> sales.bestowliving@gmail.com
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
