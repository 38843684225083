import * as Icon from 'react-feather'
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import React from "react";
import propertiesList from "../Models/propertyDetails.json";
import cafeDetails from "../Models/cafeDetails.json"

const CafePage = () => {

    const propertySlug = propertiesList[0].building_slug;

    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12"}>

                    {/*<!--  navbar  -->*/}
                    <div className='row justify-content-center  fixed-top py-4'>
                        <div className={'col-md-10 col-11'}>
                            {['sm'].map((expand) => (
                                <Navbar key={expand} expand={expand}
                                        className="bg-body-tertiary bg-nav-bar-container mb-3">
                                    <Container fluid className={"row"}>
                                        <Navbar.Brand className={'col-6'}>
                                            <div className={"row"}>
                                                <div className="col-md-10 col-12 d-flex justify-content-center">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-2 col-5 pe-0 ">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"/images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-3 col-7 pe-0">
                                                            <div className={'w-100 text-wrap'}>
                                                                <label
                                                                    className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                    Bestow Living</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle className={'col-2 border-0'}
                                                       aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                        <Navbar.Offcanvas
                                            className={'col-12'}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="top"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                    <div className={'row'}>
                                                        <div className="col-5 d-flex justify-content-start">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-5 col-5 ps-0">
                                                                    <Link to={"/"}>
                                                                        <img className="img-fluid"
                                                                             src={"/images/landing/co-live-logo-image.png"}
                                                                             alt="logo"/>
                                                                    </Link>
                                                                </div>
                                                                <div className="col-7 p-0">
                                                                    <label
                                                                        className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                        Bestow Living</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav
                                                    className="d-md-flex gap-3 justify-content-md-end align-items-center font-family-playfair flex-grow-1 pe-0">
                                                    <Nav>
                                                        <div>
                                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">Explore
                                                                    properties
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/about"}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">About
                                                                    us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/contactus"}>
                                                                <button
                                                                    className="btn-bg btn-contact-us font-size-14 font-weight-500 font-color-neutral-white"
                                                                    type="button">Contact us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>
                            ))}
                        </div>
                    </div>


                    {/*    hero section   */}
                    <div className={"row bg-cafe-hero-image"}>
                        <div className={"col-12 py-2"}></div>
                        <div className={"col-12 d-flex flex-column justify-content-center"}>
                            <div className={"row d-flex justify-content-md-end justify-content-center"}>
                                {cafeDetails.map(each => (
                                    <div className={"col-11 col-md-5 "} key={each.id}>
                                        {/* location container */}
                                        <div className={""}>
                                            <div className={"row "}>
                                                <div className={"col-8 col-md-6 cafe-location-container"}>
                                                    <div className={"d-flex align-items-center gap-2"}>
                                                        <Icon.MapPin className={"font-color-neutral-white"}/>
                                                        <div>
                                                            <label
                                                                className={"font-size-18 font-weight-500 font-family-inter font-color-neutral-white"}>{each.location}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* cafe details*/}
                                        <div className={"my-4 py-md-3 "}>
                                            <div>
                                                <label
                                                    className={"font-size-64 font-weight-700 font-color-theme-primary2"}>{each.name}</label>
                                            </div>
                                            <div className={"mt-md-3 mt-2"}>
                                                <label
                                                    className={"font-size-18 font-weight-400 font-family-inter font-color-neutral-grey-lightest"}>
                                                    {each.description}
                                                </label>
                                            </div>
                                        </div>
                                        <div className={"d-flex align-items-center gap-md-4 gap-2"}>
                                            <div>
                                                <label
                                                    className={"font-size-18 font-weight-500 font-color-neutral-white font-family-inter"}>Available
                                                    on:</label>
                                            </div>
                                            <div
                                                className={"d-flex justify-content-start align-items-center gap-md-4 gap-2"}>
                                                <div>
                                                    <img className={"img-fluid"} src={"/images/cafe/swiggy-logo.png"}
                                                         alt={"swiggy logo"}/>
                                                </div>
                                                <div>
                                                    <label
                                                        className={"font-color-neutral-grey-light font-size-14 font-weight-400 font-family-inter"}>&</label>
                                                </div>
                                                <div>
                                                    <img className={"img-fluid"} src={"/images/cafe/zomato-logo.png"}
                                                         alt={"zomato logo"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className={"col-1 d-none d-md-block"}></div>
                            </div>
                        </div>
                    </div>

                    {/*  offers section  */}
                    <div className={"row justify-content-center py-md-4"}>
                        <div className={"col-10 my-5 bg-discount-container"}>
                            <div className={"row d-flex justify-content-md-between "}>
                                <div className={"col-md-1 col-12 py-3 py-md-0"}></div>
                                <div
                                    className={"col-md-4 col-10 py-md-5 my-md-4 d-flex flex-column justify-content-center"}>
                                    <div>
                                        <label className={"font-size-64 font-weight-700 font-color-orange-50"}>
                                            20% OFF
                                        </label>
                                        <div>
                                            <label className={"font-size-14 font-color-orange-200"}>Only for PG
                                                residents</label>
                                        </div>
                                    </div>
                                    <div className={"pt-4 mt-3"}>
                                        <label
                                            className={"font-size-14 font-weight-500 font-color-neutral-white discount-note-container"}>Note:
                                            Cafe is open to everyone</label>
                                    </div>
                                </div>
                                <div className={"col-md-7 col-12 mt-4 mt-md-0 p-0"}>
                                    <div className={"w-100 h-100"}>
                                        <img className={"img-fluid h-100 w-100"}
                                             src={"/images/cafe/cafe-discount-image.png"} alt={"cafe discount image"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Our Cuisines  */}
                    <div className={"row py-md-4 justify-content-center"}>
                        <div className={"col-10 col-md-10 my-4 py-2"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"w-100 d-flex justify-content-center"}>
                                        <label className={"font-size-42 font-weight-700 font-color-grey-900"}>Our
                                            Cuisines</label>
                                    </div>
                                </div>
                            </div>

                            <div className={"row mt-md-2 justify-content-center gap-5"}>
                                {cafeDetails.map(each => (
                                    each.our_cuisines.map(eachCuisines => (
                                        <div
                                            className={"col-12 col-md-5 mt-md-5 mt-4 border-0 bg-white our-cuisines-box-shadow"}
                                            key={eachCuisines.id}>
                                            <div className={"row h-100"}>
                                                <div
                                                    className={"col-md-7 col-12 order-1 order-md-0 p-md-4 p-3 d-flex flex-column justify-content-center align-items-center"}>
                                                    <div className={""}>
                                                        <label
                                                            className={"font-size-32 font-weight-700 font-color-grey-900"}>
                                                            {eachCuisines.food_type}
                                                        </label>
                                                        <div className={"mt-3"}>
                                                            <label
                                                                className={"font-size-14 font-weight-500 font-family-inter font-color-grey-500"}>
                                                                {eachCuisines.food_description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={"col-md-5 col-12 object-fit-cover justify-content-end px-0"}>
                                                    <div className={"w-100 h-100 d-flex justify-content-end"}>
                                                        <img
                                                            className={"object-fit-cover w-100 h-100 food-image-radius"}
                                                            src={eachCuisines.food_image} alt={eachCuisines.alt}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ))}
                            </div>

                        </div>
                    </div>

                    {/*  Menu  */}
                    <div className={"row justify-content-center py-md-4"}>
                        <div className={"col-md-10 col-11 my-4 py-2"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"w-100 d-flex justify-content-center"}>
                                        <label
                                            className={"font-size-42 font-weight-700 font-color-grey-900"}>Menu</label>
                                    </div>
                                </div>
                            </div>

                            <div className={"row pt-md-3"}>
                                <div className={"col-12 col-md-6"}>
                                    {cafeDetails.map((each_menu) => (
                                        each_menu.menu.map((menu) => (
                                            <div className={"pb-4 mt-4 menu-item-border w-100"} key={menu.id}>
                                                <label className={"font-size-24 font-weight-700 font-color-grey-900"}>
                                                    {menu.menu_item}
                                                </label>
                                                <div className={"pt-2"}>
                                                    <label
                                                        className={"font-color-grey-400 font-weight-400 font-size-18"}>{menu.item_type}</label>
                                                </div>
                                            </div>
                                        ))
                                    ))}
                                    <div className={"mt-4 pt-3"}>
                                        <button type={"button"}
                                                className={"button-border rounded-3 font-weight-500 font-size-18 font-family-inter font-color-theme-primary2 btn-cafe-gradient bg-transparent"}>See
                                            complete menu
                                        </button>
                                    </div>
                                </div>
                                <div className={"col-12 col-md-6 d-none d-md-block"}>
                                    <img className={"img-fluid h-100 w-100"} src={"/images/cafe/menu-image.png"}
                                         alt={"menu image"}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row justify-content-center py-4"}>
                        <div className={"col-12 col-md-10 my-4 py-2"}>
                            <div className={"row d-md-flex justify-content-md-between justify-content-center"}>
                                <div className={"col-6 d-none d-md-block"}>
                                    <img className={"img-fluid w-100 h-100"} src={"/images/cafe/food-images.png"}
                                         alt={"food images"}/>
                                </div>
                                <div className={"col-md-6 col-11"}>
                                    <div>
                                        <img className={"img-fluid"} src={"/images/cafe/food-dish-image.png"}
                                             alt={"food dish image"}/>
                                    </div>
                                    <div className={"mt-3"}>
                                        <label className={"font-size-42 font-weight-700 font-color-grey-900"}>Experience
                                            Our Exceptional Catering Service</label>
                                    </div>
                                    <div className={"mt-3"}>
                                        <label
                                            className={"font-size-18 font-weight-400 font-color-neutral-grey-light font-family-inter"}>
                                            Looking for the best catering service? We've got you covered! From small
                                            parties to big celebrations, our catering will ensure your guests are happy.
                                        </label>
                                    </div>
                                    <div className={"mt-4 pt-3"}>
                                        <Link to={"/catering"}>
                                            <button type={"button"}
                                                    className={"button-border btn-cafe-gradient font-weight-500 font-size-18 font-family-inter font-color-theme-primary2 bg-transparent"}>Contact
                                                us
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<!--footer section-->*/}
                    <div className="row bg-footer-color justify-content-center">
                        <div className="col-12 col-md-10 py-4 my-4">
                            <div className="row justify-content-start font-family-inter">
                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 col-md-10">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-4 col-3">
                                                    <Link to={"/"}>
                                                        <img className="img-fluid"
                                                             src={"/images/landing/co-live-logo-image.png"} alt="logo"/>
                                                    </Link>
                                                </div>
                                                <div className="col-md-4 col-3 ps-0 p-md-0">
                                                    <label
                                                        className="font-size-14 font-weight-600 font-color-theme-primary2 font-family-inter">Le
                                                        Bestow Living</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="pt-4">
                                                <label
                                                    className="font-size-14 font-weight-400 font-color-neutral-grey-lightest">©
                                                    2024 Bestow. All rights reserved.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="row ">
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Company
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={`/exploreproperties/${propertySlug}`}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Explore
                                                            properties
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/about"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">About
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Contact
                                                            us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-4 px-md-0 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Support
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <a href="#">
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Help
                                                            center
                                                        </button>
                                                    </a>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <a href="#">
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Cancellation
                                                            policy
                                                        </button>
                                                    </a>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <a href="/termsandconditions">
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Terms
                                                            and conditions
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Contact
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Phone className="text-white"/>
                                                    </div>
                                                    <label
                                                        className="font-size-14 text-white font-weight-400">+91-9100009049</label>
                                                </div>
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Mail className="text-white"/>
                                                    </div>
                                                    <div>
                                                        <a href="mailto:sales.bestowliving@gmail.com"
                                                           className="font-size-14 font-color-neutral-white font-weight-400 text-decoration-none"> sales.bestowliving@gmail.com
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CafePage
