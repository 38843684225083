import {Link} from "react-router-dom";
import * as Icon from "react-feather";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import 'bootstrap/dist/css/bootstrap.css'

import propertiesList from '../Models/propertyDetails.json'

const TermsAndConditionsPage = () => {

    const propertySlug = propertiesList[0].building_slug;

    return (
        <div className="container-fluid">

            {/* navbar section */}
            <div className='row justify-content-center fixed-top py-4'>
                <div className={' col-md-10 col-11'}>
                    {['sm'].map((expand) => (
                        <Navbar key={expand} expand={expand} className="bg-body-tertiary bg-nav-bar-container mb-3">
                            <Container fluid className={"row"}>
                                <Navbar.Brand className={'col-6'}>
                                    <div className={"row"}>
                                        <div className="col-md-10 col-12 d-flex justify-content-center">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-2 col-5 pe-0 ">
                                                    <Link to={"/"}>
                                                        <img className="img-fluid"
                                                             src={"images/landing/co-live-logo-image.png"} alt="logo"/>
                                                    </Link>
                                                </div>
                                                <div className="col-md-3 col-7 pe-0">
                                                    <div className={'w-100 text-wrap'}>
                                                        <label
                                                            className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">
                                                            Le Bestow Living</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Navbar.Brand>
                                <Navbar.Toggle className={'col-2 border-0'}
                                               aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                <Navbar.Offcanvas
                                    className={'col-12'}
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="top"
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                            <div className={'row'}>
                                                <div className="col-5 d-flex justify-content-start">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-5 col-5 ps-0">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-7 p-0">
                                                            <label
                                                                className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                Bestow Living</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav
                                            className="d-md-flex gap-3 justify-content-md-end align-items-center font-family-playfair flex-grow-1 pe-0">
                                            <Nav>
                                                <div>
                                                    <Link to={`/exploreproperties/${propertySlug}`}>
                                                        <button type={"button"}
                                                                className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">Explore
                                                            properties
                                                        </button>
                                                    </Link>
                                                </div>
                                            </Nav>
                                            <Nav>
                                                <div>
                                                    <Link to={"/about"}>
                                                        <button type={"button"}
                                                                className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">About
                                                            us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </Nav>
                                            <Nav>
                                                <div>
                                                    <Link to={"/contactus"}>
                                                        <button
                                                            className="btn-bg btn-contact-us font-size-14 font-weight-500 font-color-neutral-white"
                                                            type="button">Contact us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </Nav>
                                        </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>
                    ))}
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="py-4 my-4"></div>
                    <div className="py-4"></div>
                </div>
            </div>

            <div className="row justify-content-center py-2">
                <div className="col-12 col-md-10 py-4 my-md-4 px-3 px-md-0">
                    <p>
                        <strong>
      <span style={{fontSize: "11pt"}}>
        Agreement &amp; its clauses:&nbsp;
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{fontSize: "10.5pt"}}>
      a. Nature, Definition &amp; Scope of Agreement: This entire premise
      remains in possession and control of Le Bestow Living Private Limited's
      management. THE TENANT ACCEPTS THAT THIS AGREEMENT CREATES NO TENANCY
      INTEREST, LEASEHOLD ESTATE, OR OTHER REAL PROPERTY INTEREST WITH RESPECT
      TO THE ACCOMMODATION(S). Le Bestow Living Private Limited's management is
      granting the TENANT the right to share and use the Premises on these terms
      and conditions, as supplemented by the House Rules. This agreement is
      personal to the TENANT and cannot be transferred to anyone else.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "10.5pt"}}>
      b. Validity &amp; Termination of Agreement: This agreement lasts for the
      period stated in it until terminated by the TENANT or by Le Bestow Living
      Private Limited's management. The fees upon any renewal will be at the
      then-prevailing market rate. However, the expiration or termination of
      this Agreement shall not waive, release, or otherwise relieve the TENANT
      of any liability that has accrued prior to such termination or expiration.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "10.5pt"}}>
      c. Either Le Bestow Living Private Limited's management or the TENANT can
      terminate this agreement at the end date stated in it or at the end of any
      extension or renewal period mutually decided.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "10.5pt"}}>
      d. To the maximum extent permitted by applicable law, Le Bestow Living
      Private Limited's management may terminate this agreement immediately by
      giving the TENANT a 31-day notice without the need to follow any
      additional procedure if the TENANT is in breach of any of the clauses of
      this AGREEMENT, its obligations, or the HOUSE RULES, or if Le Bestow
      Living Private Limited's management has given the TENANT notice to rectify
      a breach and the TENANT has failed to do so within 7 days of that notice,
      or due to its conduct.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "10.5pt"}}>
      e. If Le Bestow Living Private Limited's management terminates this
      agreement for any of the above reasons, it does not terminate any
      outstanding obligations, including additional services used and the
      monthly fee for the remainder of the period for which this agreement would
      have lasted.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "10.5pt"}}>
      f. The terms of this agreement are confidential. Neither Le Bestow Living
      Private Limited's management nor the TENANT may disclose them without the
      other’s consent unless required to do so by law or an official authority.
      This obligation continues after this agreement ends.
    </span>
                    </p>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
      <span style={{fontSize: "11pt"}}>
        Services, Rights &amp; Obligations of Owner &amp; Tenant
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      a. Services offered by the Owner: Le Bestow Living Private Limited's
      management is responsible for providing accommodation access in the
      property at 401. The TENANT has checked all the facilities provided in
      this unit and is satisfied before signing this AGREEMENT. However, the
      TENANT will have a nonexclusive right to the rooms allocated to it. Le
      Bestow Living Private Limited's management may need to allocate different
      accommodation(s) in case of an emergency or other unforeseen reasons, but
      these accommodation(s) will be reasonably equivalent in size, and the
      management will notify the TENANT in advance regarding such changes.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      b. Le Bestow Living Private Limited's management is also responsible for
      providing the following services to the TENANT: i. Room ii. Bed &amp;
        Mattress iii. Food iv. Internet v. Laundry Facility (Washing Machines) vi.
      Parking (if available)
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      c. Le Bestow Living Private Limited's management may need to enter the
      TENANT’s accommodation(s) and may do so at any time. However, unless there
      is an emergency or the TENANT has given notice to terminate, the
      property's management will attempt to notify the TENANT verbally or
      electronically in advance when it needs access to carry out testing,
      repairs, or works other than routine inspection, cleaning, and
      maintenance. It will also try to follow all reasonable security procedures
      to protect the privacy of the TENANT, except in cases where any of the
      terms of this agreement or house rules have been breached.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      d. If for any reason the property's management cannot provide the
      accommodation(s) by the start date of this agreement, it has no liability
      to the TENANT for any loss or damages, but the TENANT may cancel this
      agreement without penalty. It may delay the start date of this agreement
      provided it provides the TENANT with alternative accommodation(s) that
      shall be at least equivalent in size to the accommodation(s) stated in
      this agreement.
    </span>
                    </p>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
                            <span style={{fontSize: "11pt"}}>Legal &amp; Compliance</span>
                        </strong>
                        <span style={{fontSize: "11pt"}}>&nbsp;</span>
                    </p>
                    <ol>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Governing Laws: This agreement shall be interpreted and governed in
          accordance with the laws of India. The appropriate courts in the city
          of Delhi shall have exclusive jurisdiction in respect of any disputes
          or differences arising hereunder. The property's management and the
          TENANT both accept the exclusive jurisdiction of the courts in Delhi.
          If any provision of these terms and conditions is held void or
          unenforceable under applicable law, the other provisions shall remain
          in force.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Compliance: The TENANT must comply and act in accordance with all
          relevant laws and regulations applicable to his/her temporary
          occupancy at the Premises. The TENANT shall not engage in any illegal
          activities in connection with his/her use of the Premises. The TENANT
          must not do anything that may interfere with the use of the Premises
          by the property's management or by others, cause any nuisance or
          annoyance, or increase the insurance premiums, or cause loss or damage
          to the property's management (including damage to reputation) or to
          the owner of the building where the Premises are situated.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The TENANT acknowledges that (a) the terms of the above-mentioned
          clause are a material inducement in the property's management's
          execution of this agreement and (b) any violation by the TENANT of the
          above clause shall constitute a material default by the TENANT
          hereunder, entitling the property's management to terminate this
          agreement without further notice or procedure.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The TENANT shall not violate any law nor indulge in any illegal
          activity, such as ragging, cybercrime, or misuse of the internet/Wi-Fi
          provided by the property. The TENANT or any person related thereto
          shall not bring any weapons or use any drugs or psychotropic
          substances on the premises.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          In case any illegal activity is found to be carried out by the TENANT
          or its guests on the premises, in contravention/violation of the law
          of the land, any consequences which follow shall be the exclusive and
          sole responsibility and liability of the TENANT only, and the
          property's management shall not take any responsibility whatsoever for
          such unlawful activities. Furthermore, the property's management shall
          have the right to vacate the Premises immediately without any prior
          intimation to the TENANT and to claim damages and forfeit the security
          deposit.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Complaints &amp; Dispute Resolution: Le Bestow Living Private
          Limited's management shall not be responsible for any disputes between
          roommates, and such disputes should be resolved amongst themselves
          without interference from the management.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Defaming (oral or written communication of a false statement/comment
          about another person that unjustly harms his/her reputation) any
          person, including fellow tenants and the property's management, or
          influencing fellow tenants to defame any person or the property's
          management by any means (i.e., directly or indirectly, in public or on
          online platforms), is strictly prohibited. This may lead to serious
          consequences, including termination of the Agreement and appropriate
          legal action as deemed necessary.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          In case of any complaints related to facilities provided to the
          TENANT, the safety of the TENANT, or anything concerning the TENANT's
          stay in the property, the TENANT shall first raise the complaint
          officially on our TENANT app (powered by EazyPG). If no action is
          taken within 2 working days of registering the complaint, the TENANT
          may contact the Representative of the property.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          It is expected that the TENANT must listen to the above representative
          and not get into any kind of argument. If there is any concern and the
          TENANT is not satisfied with the resolution provided, the TENANT may
          email his/her concerns to the email address
          operations.bestowliving@gmail.com or contact senior management at
          contact no. +91 9100009059. However, no pending complaint shall be an
          excuse to not pay the rent or utility bills or any other duly applied
          charges by the TENANT.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          All disputes if escalated shall be subject to the jurisdiction of
          Hyderabad.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
                            <span style={{fontSize: "11pt"}}>Owner's Liability</span>
                        </strong>
                        <span style={{fontSize: "11pt"}}>&nbsp;</span>
                    </p>
                    <ol>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          to the TENANT for any loss or damage the TENANT suffers in connection
          with this agreement, the services, or the TENANT’s accommodation(s).
          It is also not liable for any loss resulting from its failure to
          provide a service due to mechanical breakdown, strike, termination of
          its interest in the building where the Premises is situated, or any
          other reason.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={2}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          In no Force Majeure events shall the property's management be liable
          for any loss or damage. Force Majeure events shall mean and include
          acts of God such as fire, storm, flood, earthquake, explosion, or
          accident; acts of the public enemy; acts of war or terrorism;
          rebellion; insurrection; sabotage; failures or delays in
          transportation; strikes; acts of any government, whether national,
          municipal, or otherwise, or any agency thereof; and acts of third
          parties beyond the reasonable control of such Party.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={3}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          In case of any travel restrictions, epidemics, or quarantine
          restrictions, the TENANT shall be liable to pay the accommodation fee
          for 1 month from the occurrence of any of the aforementioned
          situations unless otherwise agreed upon with the property's
          management. The accommodation fee will be chargeable if the room is
          occupied with the TENANT's belongings. If the TENANT doesn't remove
          their belongings within 15 days after leaving the Property, the
          management will not be responsible for any loss or damage to their
          belongings.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={4}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Le Bestow Living Private Limited's management shall not be responsible
          for the loss, damage, theft, or destruction of the TENANT's
          possessions. It strongly advises the TENANT to insure their
          possessions against all potential loss, damage, theft, expense, or
          liability.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={5}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Le Bestow Living Private Limited's management strongly advises the
          TENANT to obtain medical or health insurance for health and medical
          emergencies. Management shall not be liable for any external
          situations or mishaps such as suicides.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
                            <span style={{fontSize: "11pt"}}>Rent &amp; Bill Payments&nbsp;</span>
                        </strong>
                    </p>
                    <ol>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The TENANT will be required to pay the Accommodation Fee for the
          applicable months since the start of the agreement. In addition to the
          Accommodation Fee, the TENANT is liable to pay a security deposit as
          mentioned in the agreement, which will be held by the property's
          management without generating interest as security against all
          obligations of the TENANT under this agreement.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={2}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The security deposit or any balance after deducting outstanding fees
          and other applicable costs due to the tenant will be returned to the
          TENANT only after the TENANT has settled its account with the
          property's management.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={3}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The security deposit shall be forfeited if the TENANT leaves the
          property before the expiry of the "Lock-in period of 0".
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={4}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The TENANT may be charged a one-time registration &amp; joining fee
          per person as communicated/agreed upon at the time of the agreement.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={5}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          If the TENANT does not pay the monthly accommodation fees within the
          due date (7th Day of every month), a late payment fee equivalent to up
          to INR 100 per day over and above the Monthly Accommodation Fee shall
          be payable until the actual date of payment.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={6}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Le Bestow Living Private Limited's management also reserves the right
          to withhold services (including denying the TENANT access to its
          accommodation(s)) while there are any outstanding fees and/or
          penalties and/or the TENANT is in breach of this agreement.
          Furthermore, in the case of late payment, it reserves the right to use
          the security deposit, terminate the agreement, and recover any
          additional costs over and above the security deposit.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={7}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The TENANT will be liable to pay a fee of Rs. 1,500/- for any bounced
          cheque or any other declined payments due to insufficient funds.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={8}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Le Bestow Living Private Limited's management supports "Digital India
            &amp; Digital Payments" and strives to reduce environmental impact.
          Hence, all the invoices and receipts shall be sent electronically via
          our TENANT App (powered by EazyPG) or via push notifications or via
          WhatsApp messages/SMS or via emails.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={9}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Le Bestow Living Private Limited's management and its technology
          partner EazyPG (EazyApp Tech Pvt Ltd) reserve the right to send
          intimations, reminders, discounts, offers, late fine communication, or
          any other relevant information to the TENANT electronically via our
          TENANT App (powered by EazyPG) or via push notifications or via
          WhatsApp messages/SMS or via emails.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={10}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Le Bestow Living Private Limited's management can increase the monthly
          accommodation fee at its discretion after giving due notice to the
          TENANT.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={11}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The Accommodation fee and any recurring services requested by the
          TENANT are payable in advance. Unless otherwise agreed in writing,
          these recurring services will be provided by the property's management
          at the specified rates for the duration of this Agreement.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={12}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          If the TENANT benefited from a special discount, promotion, or offer,
          the property's management or our technology partner EazyPG (EazyApp
          Tech Pvt Ltd) may revoke that discount, promotion, or offer without
          notice.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={13}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Any payments received from the TENANT such as Booking Amount, Rent,
          Electricity Bill are NON-Refundable under any Circumstances. Security
          Deposit will be refunded within 7 days starting from the date of
          TENANT's eviction from the premises. Any Charges to be paid by the
          TENANT will be deducted from the Security Deposit, and the remaining
          amount will be refunded.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={14}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          A One-Time exit/Maintenance charge of Rs.1,500/- will be deducted from
          the TENANT's Security Deposit at the time of the TENANT's eviction
          from the premises.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={15}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          During the Notice period, TENANT must pay the Rent as usual without
          any negotiations.
        </span>
                            </p>
                        </li>
                    </ol>
                    <ol start={16}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Property’s Management reserves the right to increase the Monthly Rent
          at any time during the stay with prior notice before 30 Days. It is up
          to the TENANT to stay/leave the premises in case of an increase in
          Rent and has to be communicated 30 Days prior to the eviction from the
          Premises.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
                            <span style={{fontSize: "11pt"}}>Part 2 - House Rules</span>
                        </strong>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>Our TENANT App (powered by EazyPG)</span>
                    </p>
                    <ol>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          We are a smart &amp; digital Property powered by our technology
          partner, EazyPG. We have an exclusive TENANT app for all our TENANTS
          to facilitate an effortless &amp; comfortable stay in our Property.
          Our TENANTS do not have to call or message for anything. They can
          access everything at their fingertips on their smartphones with their
          TENANT app.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          For convenience and secure digital records, we strongly recommend
          doing the following activities only via our TENANT app (powered by
          EazyPG):
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <span style={{fontSize: "11pt"}}>a. TENANT KYC -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. As per the tenancy model act, we are required to complete your Joining
      Form, Rent Agreement for safety reasons. To make it easy for you, we have
      made it completely digital. Therefore, you must enter your complete
      details, Govt ID &amp; other required information on our TENANT app within
      24 hours of the Date of Joining.
    </span>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>b. Payments -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. All dues such as Rent, Electricity, water, maintenance will be shown on
      a real-time basis on our tenant app (powered by EazyPG).
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      ii. The TENANT will make payments only via the Exclusive Payment link
      shared on their WhatsApp numbers or via our Tenant App or our exclusive
      payment QR (powered by EazyPG).
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      iii. Our payment methods support all popular UPI apps including Paytm,
      PhonePe, GPay, BHIM, NEFT/ RTGS/ CREDIT CARD/ DEBIT CARD. There may be a
      payment/technology processing charge over the total due amount.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      iv. If the TENANT wants to pay in CASH, they can pay only via the CASH via
      OTP method in our TENANT app to THE AUTHORIZED LIST OF RENT COLLECTORS FOR
      Le Bestow Living Private Limited.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      v. Immediately after the successful payment, the TENANT will be provided
      with an automatic digital payment receipt on their WhatsApp/Email &amp;
        tenant app. There will not be any paper receipt provided.
    </span>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>c. Food menu -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. Every week's latest food menu &amp; timings will be updated on our
      tenant app. You don't have to call or message anyone to know the food menu
        &amp; timing. If the food menu is not updated, you may raise a complaint
      from within the tenant app.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      ii. If the TENANT arrives after the meal timings have passed, it will be
      the tenant's responsibility to arrange food for oneself.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      d. Announcements/Digital Notice Board -
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. All the relevant &amp; important information, notices &amp;
        announcements (such as electricity cuts, Water issues, Wi-Fi, Mess
      timings, etc.) will be sent to you on our tenant app (powered by EazyPG).
      You will also be notified for the new announcements &amp; messages via
      WhatsApp/SMS by EazyPG.
    </span>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>e. Complaints -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. This property's management will always welcome feedback, complaints,
      suggestions, or inquiries from the tenants.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      ii. On noticing any maintenance issues such as plumbing, wiring, etc. in
      your room or anywhere on the property, you are immediately requested to
      register an issue/complaint through our tenant app.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      iii. However, the TENANT must register only genuine complaints and not
      misuse it. If found registering fake complaints, there may be a fine of Rs
      500.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      iv. The TENANT will receive all real-time notifications on their
      complaints when a team member is assigned to the issue and when the issue
      is resolved.
    </span>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>f. Eviction Notice -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. Property has a minimum of 30 days' notice period. In case the TENANT
      wishes to leave the property, he/she has to give advance notice of 30 days
      via our tenant app. Any other mode of leave/eviction notice will not be
      entertained.
    </span>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>g. Late Check-in -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. In the case of late-night entry, the TENANT is required to register a
      "Late Check-in Request" by 9 pm via our tenant app.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      ii. The entry gates are closed by 9:30 pm, i.e., night curfew time at our
      property. In the absence of a Late Check-in request, the TENANT shall not
      be allowed to enter.
    </span>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>h. Guest Invite -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. Guests are NOT allowed into the TENANT's rooms/Floor Lobby areas.
      Guests are welcome to the common area and will be allowed only between 10
      AM – 6 PM. No Guest will be allowed to stay inside the premises before 10
      AM or after 6 PM.
    </span>
                    </p>
                    <p>
                        <span style={{fontSize: "11pt"}}>i. Leave &amp; Attendance -</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. In case of going on leave from the property, the TENANT is required to
      inform the same from our tenant app.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      ii. Unless approved otherwise, there shall not be any deductions from the
      monthly accommodation fee or any other recurring fee being charged to the
      TENANT.
    </span>
                    </p>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
        Rules for Rooms &amp; Common Areas
      </span>
                        </strong>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
        Taking Care of Places &amp; Furniture, etc.
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
      i. The TENANT is responsible for keeping their rooms, bathrooms, and
      common areas in the premises such as reception, staircase, and lounge
      areas, etc., clean and tidy at all times.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
      ii. The TENANT is expected to use the lifts with care, and movement of any
      heavy equipment using the lifts will require prior permission from the
      property's management team.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
      iii. All food kept in the room/common area/pantry must be stored in
      suitable containers or wrapped tightly to prevent insects or rodents from
      accessing it. Stale, unfinished, or waste food must be immediately thrown
      into the dustbins provided in the pantry.
    </span>
                    </p>
                    <ol>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          iv. The TENANT must keep the keys of their rooms and almirah safe. In
          case of loss, they will have to bear the cost of replacement of keys.
          The locks for the almirah and the main door are not allowed to be
          changed without informing the property's management representative.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
        Harm of Property/Vandalism
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
      i. Vandalism is a serious offense. Vandalism shall mean and include any
      and all acts of destruction, damage, and/or defacement of any asset or
      property owned by us. The TENANT found guilty of committing such an
      offense can be evicted from the hostel and required to pay all costs
      incurred by us due to such damage or destruction.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
      ii. Any damage to the House must be reported by the TENANT immediately to
      the property's management. The TENANT will be liable to pay for the
      expenses or costs to the property arising from use/misuse of the same by
      the TENANT, except for damages caused by normal wear and tear. The charges
      can also be deducted from the security deposit.
    </span>
                    </p>
                    <ol start={2}>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          iii. The TENANT may paste posters in the rooms assigned to them after
          permission, but they should ensure that any such pasting does not
          cause any damage to the walls or rooms or to any other parts of the
          property. The TENANT must not put any nails or fixtures in the wall
          without management's approval.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
        Movement of Items/Place of Usage
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
      i. Any items of common use (e.g., microwave, iron, kettle, induction top,
      magazines, crockery, common TV units) provided by the property's
      management as part of the amenities package shall be used only in the
      common areas and not in individual rooms.
    </span>
                    </p>
                    <ol start={3}>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          ii. No furniture or furnishings in the common areas of the House may
          be moved by the TENANT at any point in time.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
        Prohibited Activities
      </span>
                        </strong>
                    </p>
                    <p>
                        <strong>
                            <span style={{color: "#0d0d0d", fontSize: "12pt"}}>a. Gambling</span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      i. Betting and gambling by any means are strictly prohibited in the House.
    </span>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
        b. Smoking/Drinking
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      i. Distribution and consumption of tobacco and alcohol are strictly
      prohibited within the property. Further, possession, distribution, and
      consumption of recreational drugs and/or psychotropic substances are
      strictly prohibited on its Properties.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      ii. If deemed necessary, this property's management may require any TENANT
      to undertake a breath-analyzer test.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      iii. Drinking &amp; Smoking are strictly prohibited on the premises. A
      freshening fee of Rs 2,000/- will be levied if any resident is found
      smoking in the room.
    </span>
                    </p>
                    <p>
                        <strong>
                            <span style={{color: "#0d0d0d", fontSize: "12pt"}}>c. Ragging</span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      i. Ragging in any form whatsoever is strictly prohibited and is a
      cognizable offense under the Indian Penal Code, 1860. All instances of
      ragging brought to the notice of this property's management will be
      reported to the appropriate legal authorities.
    </span>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
        d. Misbehavior with Fellow TENANT
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      i. The TENANT must always behave in accordance with the property's
      Anti-Harassment Policy and shall not harass any persons. Further, all
      tenants must respect the personal beliefs of other tenants, such that no
      TENANT hurts any religious sentiments, beliefs, or practices in any way
      whatsoever.
    </span>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
        e. Misbehavior with Team
      </span>
                        </strong>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      i. It is the responsibility of the TENANT to cooperate with the Property's
      management, the night warden, the food providers, the housekeeping and
      security staff to ensure that all services are provided smoothly.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      ii. We treat all its employees and service providers including
      housekeeping, security, and food vendors with dignity and respect. It is
      expected that the TENANT also uphold this principle in their dealings with
      all our employees, representatives, and service providers.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      f. Activities of Indiscipline
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      i. Making loud noises, including playing loud music between 9.00 PM to
      6.00 AM is strictly prohibited.
    </span>
                    </p>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
      ii. The TENANT should not behave in any manner which is deemed to be
      offensive by the neighbors or by the society either in the premises or in
      the neighboring areas surrounding the premises.
    </span>
                    </p>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
                            <span style={{fontSize: "12pt"}}>Food &amp; Mess Rules</span>
                        </strong>
                    </p>
                    <ol>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Cooking food in any area except the kitchen area is strictly
          prohibited.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Personal food items can be stored in common refrigerators. The TENANT
          should adequately label their food items. Property's management will
          not be liable for the consumption of personal food items by other
          tenants.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The TENANT should ensure that the pantry area is clean after their
          use. All dirty dishes should be placed in the sink, and any leftover
          and/or garbage must be thrown in the appropriate dustbins. The TENANT
          will be liable for damage to common utensils.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          If food is ordered from outside by the TENANT, then all leftovers and
          packaging materials should be disposed of in appropriate dustbins.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Unless permission is given to take meals into their individual rooms,
          the TENANT shall consume all food items only in the designated mess
          area. Taking cutlery and utensils to the room (including plates) is
          not allowed.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          For every meal, the TENANT may be required to punch their attendance
          in our TENANT app (Powered by EazyPG) or in any way as communicated by
          this property's management to mark the food attendance before taking
          the food.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          TENANT must avoid stocking up the fridge unnecessarily. The
          housekeeping staff will get rid of any leftovers found in the
          refrigerator.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Select dishes may be served in limited grammages or quantities.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
      <span style={{fontSize: "11pt"}}>
        Appliances &amp; Utility Consumption Rules (Electricity, Water, Wi-Fi,
        etc.)
      </span>
                        </strong>
                    </p>
                    <ol>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Prepaid electricity meters or sub-meters, wherever applicable, have
          been installed in each room by Le Bestow Living Private Limited's
          management. Electricity consumption will be billed at the rate of Rs.
          16.0/unit, an amount that is to be shared by all the tenants of the
          room.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          It is expected that if one roommate is not present for a certain
          number of days while the other roommate continues to reside in the
          room, the electricity expense for the room will continue to be shared
          equally between the roommates until mutually decided otherwise between
          the roommates based on their common understanding.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          The amounts payable will remain payable for the room, irrespective of
          the number of persons occupying the room. Any disputes between
          roommates related to billing or splitting electricity bill should be
          resolved amongst them, and Property's management shall not interfere
          in such disputes.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Water should be used judiciously. The TENANT should report any water
          leakages to the security/property manager as soon as the same is
          noticed.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <span style={{fontSize: "11pt"}}>Wi-Fi / Internet Rules</span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      i. Internet Bandwidth speed will depend on the service provider and the
      House location. Speed will be limited per user and may be changed as per
      management policy.
    </span>
                    </p>
                    <p>
    <span style={{fontSize: "11pt"}}>
      ii. We may have a restriction on the maximum number of devices per user.
      One user can access the internet on a limited number of devices at a time
      (limit of devices may vary from time to time).
    </span>
                    </p>
                    <ol start={5}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          iii. Browsing access: The TENANT is not allowed to:
        </span>
                            </p>
                        </li>
                    </ol>
                    <ul>
                        <li style={{listStyleType: "disc", fontSize: "11pt"}}>
                            <p>
                                <span style={{fontSize: "11pt"}}>Access pornography.</span>
                            </p>
                        </li>
                        <li style={{listStyleType: "disc", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Create and maintain fake accounts.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "disc", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Download and view pirated content.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "disc", fontSize: "11pt"}}>
                            <p>
                                <span style={{fontSize: "11pt"}}>Fake IP addresses.</span>
                            </p>
                        </li>
                        <li style={{listStyleType: "disc", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Piggybacking: Using someone else’s credentials.
        </span>
                            </p>
                        </li>
                    </ul>
                    <ol start={6}>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
                                <span style={{fontSize: "11pt"}}>Usage of Electrical Appliances</span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          Usage of high-wattage appliances like heaters, coils, iron boxes, or
          any other heating/cooking electrical gadget is strictly prohibited
          inside the room unless the room has a prepaid meter or submeter with
          the roommate's consent.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          While leaving the room premises, TENANTs are to ensure that they
          switch off all lights, fans, electrical appliances, including ACs,
          heaters, geysers, mosquito-repellant machines, etc.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          In case of power being run on backup, usage of electrical items
          especially ACs, geysers, washing machines, elevators will be
          restricted. Management can also take the liberty of revoking the
          electricity access of these temporarily to conserve power backup.
        </span>
                            </p>
                        </li>
                        <li style={{listStyleType: "decimal", fontSize: "11pt"}}>
                            <p>
        <span style={{fontSize: "11pt"}}>
          TENANTs should refrain from overloading the washing machines, ensuring
          that clothes are unloaded immediately after usage. In case clothes are
          found lying unclaimed, Property staff will not be held responsible if
          misplaced.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "12pt"}}>
        Housekeeping Rules
      </span>
                        </strong>
                    </p>
                    <ol>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Cleaning of the room may be carried out in the absence of TENANTs as
          well, so the TENANTs are advised not to leave their valuables open and
          unsecured in their room. The TENANTs must ensure their valuables are
          always safely placed in storage, and rooms are always kept locked.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          In case the TENANT is not available at the time of housekeeping, the
          room can be cleaned at a later time on request depending on the
          availability of the housekeeping staff in the residence.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Littering the room and common spaces is not allowed.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          The TENANT is expected to cooperate in the periodic (quarterly) pest
          control/fumigation/preventive maintenance activity.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Please note the services of the housekeeping staff can’t be utilized
          for any personal work. For example, no personal utensils will be
          washed by the housekeeping staff.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
    <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
      Laundry Rules if provided to the TENANT
    </span>
                    </p>
                    <ol>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Laundry services are meant for regular daily wear only. For hygiene
          considerations, no underclothing or socks will be accepted for
          Laundry.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          The following items should not be given in general laundry service, as
          they can get damaged:
        </span>
                            </p>
                            <ul>
                                <li
                                    style={{listStyleType: "disc", color: "#0d0d0d", fontSize: "11pt"}}
                                >
                                    <p>
            <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
              Any delicate or expensive branded clothing
            </span>
                                    </p>
                                </li>
                                <li
                                    style={{listStyleType: "disc", color: "#0d0d0d", fontSize: "11pt"}}
                                >
                                    <p>
            <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
              Woollens, jackets, leather items, embroidered clothing
            </span>
                                    </p>
                                </li>
                                <li
                                    style={{listStyleType: "disc", color: "#0d0d0d", fontSize: "11pt"}}
                                >
                                    <p>
            <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
              Items strictly meant for dry cleaning.
            </span>
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        <br/>
                    </p>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
        Security &amp; Check-in/Check-out Rules
      </span>
                        </strong>
                    </p>
                    <ol>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          TENANT shall intimate the management immediately if any critical or
          transferable illness is detected to prevent the other tenants from
          getting infected.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          The TENANT shall not consume any medication except which is prescribed
          by a registered medical practitioner.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          In case of any emergency or crisis, the TENANT must follow the
          guidelines of this property's management/Security Officer/Govt. Nodal
          officer strictly.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          The TENANT shall not leave the gates open while leaving the premises
          during Night Hours. The TENANT will be responsible for any loss or
          damage to the property of property or fellow tenants due to such
          negligence (Night Hours may vary from time to time. The tenants shall
          check our TENANT app announcements for the same).
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          The TENANT is strongly advised to keep secure and lock all their
          valuables e.g. mobile phones, laptop, watches, money etc. at all
          times. While going on leave, they must keep all their belongings
          securely packed and locked in their labelled bags and cupboards.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Building repairs and maintenance are continuous affairs, and
          Property's management reserves the right to open and enter any room
          while the student is on leave to carry out such maintenance work.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Possession, distribution, and use of firearms, lethal weapons
          (including air guns), contraband drugs, toxic and hazardous materials
          are strictly prohibited in the residences. We reserve the right to
          search bags of the TENANT as well as their visitors at any time and
          confiscate any prohibited substances that may be found in their
          luggage or in their possession.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          For Scooty/Car/vehicle parking, TENANT must have the necessary
          permission from the management before parking.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          In case of any emergency, please contact Property's management.
          Important contact numbers will be provided to all tenants at the time
          of move-in or in our TENANT app (Powered by EazyPG).
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          For security purposes, no external person/delivery boy of Food
          apps/eCommerce Company Restaurants is allowed on your floors. Kindly
          receive &amp; entertain them at the reception or entry gate of the
          Property.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Tampering with any security or fire-fighting equipment is strictly
          prohibited. The TENANT responsible for such actions will be held
          responsible for the cost of restoring the damaged equipment.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          In case of any emergencies in the building, all the tenants should be
          patient and cooperate with Property's management.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Property's management reserves its right to hand over its Property to
          the Government Authorities in case there is any
          notice/order/directions from the District Administration or State
          Government to acquire its Property during an emergency. The room
          occupied by the TENANT may be vacated, and their belongings may be
          shifted in their absence for necessary compliance with such Government
          Order.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          The TENANT shall inform Property's management (if available) before
          dialling Police Emergency Numbers i.e. '100' in case of any emergency.
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          The TENANT shall immediately inform Property's management for any
          engagements or encounters with Police to avoid surprise visits by
          Police Authorities at the Premises.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
        Visitors &amp; Guesting Rules
      </span>
                        </strong>
                    </p>
                    <ol>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Visitors/Guests are NOT allowed into the TENANTS' rooms/Floor Lobby
          areas. Guests are welcome to the common area and will be allowed only
          between 10 AM – 6 PM. No Guest will be allowed to stay inside the
          premises before 10 AM or after 6 PM.
        </span>
                            </p>
                        </li>
                    </ol>
                    <p>
                        <strong>
      <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
        Penalties &amp; Charges
      </span>
                        </strong>
                    </p>
                    <ol>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Smoking/Drinking in the premises - Rs. 2000 per instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Bringing visitors to the premises - Rs. 1,000 per instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Bringing visitors without registering with security - Rs. 2,000 per
          instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Obstructing or changing the view of CCTV cameras - Rs. 2,000 per
          instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Taking food and utensils to the room without permission - Rs. 500 per
          instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Loss of Room keys - Rs. 1500 per instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Loss of Cupboard keys - Rs. 1000 per instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Leaving lights/water taps open in absence - Rs. 500 per instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Leaving the main door open while moving out at night - Rs. 1000 per
          instance
        </span>
                            </p>
                        </li>
                        <li
                            style={{listStyleType: "decimal", color: "#0d0d0d", fontSize: "11pt"}}
                        >
                            <p>
        <span style={{color: "#0d0d0d", fontSize: "11pt"}}>
          Using Electrical Appliances in the rooms other than rooms having
          prepaid electrical meters - Rs. 1,000 per instance.
        </span>
                            </p>
                        </li>
                    </ol>
                </div>

            </div>

            {/*  footer section  */}
            <div className="row bg-footer-color  justify-content-center">
                <div className="col-12 col-md-10 py-4 my-4">
                    <div className="row font-color-neutral-white font-family-inter">
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-12 col-md-10">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-4 col-3">
                                            <Link to={"/"}>
                                                <img className="img-fluid" src={"images/landing/co-live-logo-image.png"}
                                                     alt="logo"/>
                                            </Link>
                                        </div>
                                        <div className="col-md-4 col-3 ps-0 p-md-0">
                                            <label
                                                className="font-size-14 font-weight-600 font-color-theme-primary2 font-family-inter">Le
                                                Bestow Living</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="pt-4">
                                        <label
                                            className="font-size-14 font-weight-400 font-color-neutral-grey-lightest">©
                                            2024 Bestow. All rights reserved.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="row ">
                                <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                    <div className="pb-md-4 pb-3">
                                        <button type="button"
                                                className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Company
                                        </button>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="pb-md-4 pb-3">
                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                <button type="button"
                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Explore
                                                    properties
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="pb-md-4 pb-3">
                                            <Link to={"/about"}>
                                                <button type="button"
                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">About
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="pb-md-4 pb-3">
                                            <Link to={"/contactus"}>
                                                <button type="button"
                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Contact
                                                    us
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 px-md-0 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                    <div className="pb-md-4 pb-3">
                                        <button type="button"
                                                className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Support
                                        </button>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="pb-md-4 pb-3">
                                            <Link to={""}>
                                                <button type="button"
                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Help
                                                    center
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="pb-md-4 pb-3">
                                            <Link to={""}>
                                                <button type="button"
                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Cancellation
                                                    policy
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="pb-md-4 pb-3">
                                            <Link to={'/termsandconditions'}>
                                                <button type="button"
                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Terms
                                                    and conditions
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                    <div className="pb-md-4 pb-3">
                                        <button type="button"
                                                className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Contact
                                        </button>
                                    </div>
                                    <div className="d-flex flex-column ">
                                        <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                            <div>
                                                <Icon.Phone className="text-white"/>
                                            </div>
                                            <label className="font-size-14 font-weight-400">+91-9100009059</label>
                                        </div>
                                        <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                            <div>
                                                <Icon.Mail className="text-white"/>
                                            </div>
                                            <div>
                                                <a href="mailto:sales.bestowliving@gmail.com"
                                                   className="font-size-14 font-color-neutral-white font-weight-400 text-decoration-none"> sales.bestowliving@gmail.com
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage
