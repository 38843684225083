import * as Icon from "react-feather";
import React, {useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import propertiesList from "../Models/propertyDetails.json";
import {TailSpin} from "react-loader-spinner";

const GameBookingForm = () => {

    const propertySlug = propertiesList[0].building_slug;
    const [loadingButton, setLoadingButton] = useState(false);

    const handleSubmitButton = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('contact-form');
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,

        })
            .then(() => {
                alert("Success!");
                setLoadingButton(false);
                form.reset();
            }).catch(() => {
            alert("Failure!");
            setLoadingButton(false);
            form.reset();
        });
    }

    return (
        <div className="container-fluid">
            <div className="row bg-contact-us-section">
                <div className="col-12">
                    {/* <!--  navbar  -->*/}
                    <div className='row justify-content-center bg-transparent fixed-top py-4'>
                        <div className={'col-md-10  col-11'}>
                            {['sm'].map((expand) => (
                                <Navbar key={expand} expand={expand}
                                        className="bg-body-tertiary bg-nav-bar-container mb-3">
                                    <Container fluid className={"row"}>
                                        <Navbar.Brand className={'col-6'}>
                                            <div className={"row"}>
                                                <div className="col-md-10 col-12 d-flex justify-content-center">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-2 col-5 pe-0 ">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-3 col-7 pe-0">
                                                            <div className={'w-100 text-wrap'}>
                                                                <label
                                                                    className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">
                                                                    Le Bestow Living</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle className={'col-2 border-0'}
                                                       aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                        <Navbar.Offcanvas
                                            className={'col-12'}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="top"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                    <div className={'row'}>
                                                        <div className="col-5 d-flex justify-content-start">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-5 col-5 ps-0">
                                                                    <Link to={"/"}>
                                                                        <img className="img-fluid"
                                                                             src={"images/landing/co-live-logo-image.png"}
                                                                             alt="logo"/>
                                                                    </Link>
                                                                </div>
                                                                <div className="col-7 p-0">
                                                                    <label
                                                                        className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">
                                                                        Le Bestow Living</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav
                                                    className="d-md-flex gap-3 justify-content-md-end align-items-center font-family-playfair flex-grow-1 pe-0">
                                                    <Nav>
                                                        <div>
                                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">Explore
                                                                    properties
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/about"}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">About
                                                                    us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/contactus"}>
                                                                <button
                                                                    className="btn-bg btn-contact-us font-size-14 font-weight-500 font-color-neutral-white"
                                                                    type="button">Contact us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>
                            ))}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 py-md-0 my-4 my-md-0"></div>
                            {/*<div className="py-md-4 my-md-4"></div>*/}
                        </div>
                    </div>

                    {/*<!--   contact us   -->*/}
                    <div className="row justify-content-center">
                        <div className="col-12 px-4 pt-4 mt-4 pt-md-0 mt-md-0 pb-4 mb-4">
                            <div className="row py-2 justify-content-center bg-contact-container">
                                <div className="col-10">
                                    <div className="py-md-4 my-md-4"></div>
                                    <div className="row justify-content-evenly py-md-4 py-3  my-md-4">
                                        <div className="col-12 col-md-6">
                                            <div className="font-align-center">
                                                <label className="font-size-32 font-weight-700 font-color-grey-800">
                                                    Book Your Game
                                                </label>
                                            </div>
                                            <div className="pt-4 mt-2 font-family-inter">
                                                <form id="contact-form" onSubmit={handleSubmitButton}
                                                      action={"https://script.google.com/macros/s/AKfycbzrCzYkUHU1DduPTPJX57oCYf3g7pxxI7k91B0dPlnwwObwy7Ov28oMCRczlsZZNNm2zg/exec"}
                                                >
                                                    <div className="form-group">
                                                        <label htmlFor="formGroupExampleInput"
                                                               className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Name</label>
                                                        <input type="text" required name="Name" className="form-control"
                                                               id="formGroupExampleInput"
                                                               placeholder="Enter your name"/>
                                                    </div>
                                                    <div className="form-group pt-4">
                                                        <label htmlFor="formGroupExampleInput2"
                                                               className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Email</label>
                                                        <input type="email" required name="Email"
                                                               className="form-control" id="formGroupExampleInput2"
                                                               placeholder="Enter your email"/>
                                                    </div>
                                                    <div className="form-group pt-4">
                                                        <label htmlFor="formGroupExampleInput3"
                                                               className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Phone
                                                            number</label>
                                                        <input type="text" required name="PhoneNumber"
                                                               className="form-control" id="formGroupExampleInput3"
                                                               placeholder="Enter your phone number"/>
                                                    </div>
                                                    <div className="form-group pt-4 d-flex flex-column">
                                                        <label htmlFor="formGroupExampleInput4"
                                                               className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                            Select your game
                                                        </label>
                                                        <select name={"SelectGame"} title={"Select your game"}
                                                                className={"form-control"}>
                                                            <option value={"Snooker"}
                                                                    className={"font-size-18 font-weight-400 font-color-black"}>Snooker
                                                            </option>
                                                            <option value={"Tennis"}>Tennis</option>
                                                        </select>
                                                        {/*<input type="text" required name=""*/}
                                                        {/*       className="form-control" id="formGroupExampleInput4"*/}
                                                        {/*       placeholder="select your slot"/>*/}
                                                    </div>
                                                    <div className="form-group pt-4">
                                                        <label htmlFor="formGroupExampleInput5"
                                                               className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">
                                                            Select your slot
                                                        </label>
                                                        <input type="datetime-local" required name="BookingSlot"
                                                               className="form-control" id="formGroupExampleInput5"
                                                               placeholder="select your slot"/>
                                                    </div>
                                                    <div className="form-group pt-4">
                                                        {/*<label htmlFor="formGroupExampleInput4"*/}
                                                        {/*       className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Message*/}
                                                        {/*    (optional)</label>*/}
                                                        {/*<textarea name="Message" rows="5" className="form-control"*/}
                                                        {/*          id="formGroupExampleInput4"*/}
                                                        {/*          placeholder="Message your doubts..."></textarea>*/}
                                                    </div>
                                                    {!loadingButton ? <div
                                                        className="pt-4 mt-2 d-flex justify-content-center justify-content-md-start">
                                                        <button type="submit"
                                                                className="btn-bg btn-see-details font-color-neutral-white font-size-18 font-weight-500 ">
                                                            Book Now
                                                        </button>
                                                    </div> : <div
                                                        className="pt-4 mt-2 d-flex justify-content-center justify-content-md-start">
                                                        <button type="button" disabled={true}
                                                                className="btn-bg btn-see-details">
                                                            <TailSpin height={20} width={20} color={'#1977F3'}/>
                                                        </button>
                                                    </div>}
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="d-none d-md-block">
                                                <img className="img-fluid"
                                                     src={"/images/contact-us/contact-us-avatars-image.png"}
                                                     alt="contact us avatar image"/>
                                            </div>
                                            <div className="pt-4 mt-4 d-flex flex-column gap-3 font-family-inter">
                                                <div className="d-flex align-items-start gap-3">
                                                    <div>
                                                        <Icon.Phone className="font-color-theme-primary2 "/>
                                                    </div>
                                                    <label
                                                        className="font-size-14 font-weight-400 font-color-theme-primary1">+91-9100009049</label>
                                                </div>
                                                <div className="d-flex align-items-center gap-3">
                                                    <div>
                                                        <Icon.Mail className="font-color-theme-primary2 "/>
                                                    </div>
                                                    <a href="mailto:sales.bestowliving@gmail.com"
                                                       className="font-size-14 font-weight-400 font-color-theme-primary1 text-decoration-none">
                                                        sales.bestowliving@gmail.com
                                                    </a>
                                                </div>
                                                <div className="d-flex align-items-start gap-3">
                                                    <div>
                                                        <Icon.MapPin className="font-color-theme-primary2 "/>
                                                    </div>
                                                    <label
                                                        className="font-size-14 font-weight-400 font-color-theme-primary1">
                                                        Le Bestow Living Private Limited Plot No 21, Opp Shilparamam,
                                                        Khanammet, HiTech
                                                        City, Hyderabad, Telangana - 500081
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center justify-content-md-start align-items-center gap-4 pt-4 mt-3">
                                                <div className="bg-light-sky-color p-3 rounded-circle">
                                                    <a href={"https://www.facebook.com/profile.php?id=100085934675447&mibextid=LQQJ4d"} target={"_blank"}>
                                                        <Icon.Facebook className="font-color-blue"/>
                                                    </a>
                                                </div>
                                                <div className="bg-light-pink-color p-3 rounded-circle">
                                                    <a href="https://www.instagram.com/lebestowofficial"
                                                       target="_blank">
                                                        <Icon.Instagram className="font-color-pink"/>
                                                    </a>
                                                </div>
                                                <div className="bg-color-light-dark p-3 rounded-circle">
                                                    <img className="img-fluid"
                                                         src={"/images/contact-us/twitter-icon.png"}
                                                         alt="twitter icon"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-md-4 my-md-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<!--footer section-->*/}
                    <div className="row bg-footer-color justify-content-center">
                        <div className="col-12 col-md-10 py-4 my-4">
                            <div className="row justify-content-start font-family-inter">
                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 col-md-10">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-4 col-3">
                                                    <Link to={"/"}>
                                                        <img className="img-fluid"
                                                             src={"/images/landing/co-live-logo-image.png"} alt="logo"/>
                                                    </Link>
                                                </div>
                                                <div className="col-md-4 col-3 ps-0 p-md-0">
                                                    <label
                                                        className="font-size-14 font-weight-600 font-color-theme-primary2 font-family-inter">Le
                                                        Bestow Living</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="pt-4">
                                                <label
                                                    className="font-size-14 font-weight-400 font-color-neutral-grey-lightest">©
                                                    2024 Bestow. All rights reserved.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="row ">
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Company
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={`/exploreproperties/${propertySlug}`}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Explore
                                                            properties
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/about"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">About
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Contact
                                                            us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-4 px-md-0 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Support
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <a href="#">
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Help
                                                            center
                                                        </button>
                                                    </a>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <a href="#">
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Cancellation
                                                            policy
                                                        </button>
                                                    </a>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <a href="/termsandconditions">
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Terms
                                                            and conditions
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Contact
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Phone className="text-white"/>
                                                    </div>
                                                    <label
                                                        className="font-size-14 text-white font-weight-400">+91-9100009049</label>
                                                </div>
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Mail className="text-white"/>
                                                    </div>
                                                    <div>
                                                        <a href="mailto:sales.bestowliving@gmail.com"
                                                           className="font-size-14 font-color-neutral-white font-weight-400 text-decoration-none"> sales.bestowliving@gmail.com
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameBookingForm
