import * as Icon from 'react-feather'
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {Link, Navigate, redirect} from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import React from "react";
import propertiesList from "../Models/propertyDetails.json";
import gameData from "../Models/gamingDetails.json";

const GamingPage = () => {

    const propertySlug = propertiesList[0].building_slug;

    console.log("timings", gameData.map(each => (each.available_games.map(eachTime => (console.log(eachTime.timing_details))))))


    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-12"}>

                    {/*<!--  navbar  -->*/}
                    <div className='row  justify-content-center fixed-top py-4'>
                        <div className={'col-md-10 col-11'}>
                            {['sm'].map((expand) => (
                                <Navbar key={expand} expand={expand}
                                        className="bg-body-tertiary bg-nav-bar-container mb-3">
                                    <Container fluid className={"row"}>
                                        <Navbar.Brand className={'col-6'}>
                                            <div className={"row"}>
                                                <div className="col-md-10 col-12 d-flex justify-content-center">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-2 col-5 pe-0 ">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"/images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-3 col-7 pe-0">
                                                            <div className={'w-100 text-wrap'}>
                                                                <label
                                                                    className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                    Bestow Living</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle className={'col-2 border-0'}
                                                       aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                        <Navbar.Offcanvas
                                            className={'col-12'}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="top"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                    <div className={'row'}>
                                                        <div className="col-5 d-flex justify-content-start">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-5 col-5 ps-0">
                                                                    <Link to={"/"}>
                                                                        <img className="img-fluid"
                                                                             src={"/images/landing/co-live-logo-image.png"}
                                                                             alt="logo"/>
                                                                    </Link>
                                                                </div>
                                                                <div className="col-7 p-0">
                                                                    <label
                                                                        className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                        Bestow Living</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav
                                                    className="d-md-flex gap-3 justify-content-md-end align-items-center font-family-playfair flex-grow-1 pe-0">
                                                    <Nav>
                                                        <div>
                                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">Explore
                                                                    properties
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/about"}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">About
                                                                    us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/contactus"}>
                                                                <button
                                                                    className="btn-bg btn-contact-us font-size-14 font-weight-500 font-color-neutral-white"
                                                                    type="button">Contact us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>
                            ))}
                        </div>
                    </div>

                    {/*  hero section  */}
                    <div className={"row bg-gaming-hero-image"}>
                        <div className={"col-12 py-2"}></div>
                        <div className={"col-12  d-flex flex-column justify-content-center"}>
                            <div className={"row d-flex justify-content-md-end justify-content-center "}>
                                {gameData.map(each => (
                                    <div className={"col-11 col-md-6 p-0"} key={each.id}>
                                        {/* location container */}
                                        <div className={""}>
                                            <div className={"row"}>
                                                <div className={"col-md-5 col-8 cafe-location-container"}>
                                                    <div className={"d-flex align-items-center gap-2"}>
                                                        <Icon.MapPin className={"font-color-neutral-white"}/>
                                                        <div>
                                                            <label
                                                                className={"font-size-18 font-weight-500 font-family-inter font-color-neutral-white"}>{each.location}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* gaming details*/}
                                        <div className={"w-100 mt-4 pt-md-3"}>
                                            <div className={"w-100"}>
                                                <label
                                                    className={"font-size-64 font-weight-700 font-color-neutral-white"}>{each.name}</label>
                                            </div>
                                            <div className={"mt-3"}>
                                                <label
                                                    className={"font-size-18 font-weight-400 font-family-inter font-color-neutral-grey-lightest"}>
                                                    {each.description}
                                                </label>
                                            </div>
                                            <div className={"mt-4 pt-md-3"}>
                                                <Link to={"/bookgame"}>
                                                    <button type={"button"}
                                                            className={"button-border bg-white font-color-theme-primary2 border-0"}>Book
                                                        your session
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className={"col-1 d-none d-md-block"}></div>
                            </div>
                        </div>
                    </div>

                    {/*  offers section  */}
                    <div className={"row justify-content-center py-md-4"}>
                        <div className={"col-10 my-4 bg-gaming-discount-container br-10"}>
                            <div className={"row d-flex justify-content-between"}>
                                <div className={"col-md-1 col-12 py-3 py-md-0"}></div>
                                <div
                                    className={"col-md-4 col-12 py-md-5 my-md-4 d-flex flex-column justify-content-center"}>
                                    <div>
                                        <label className={"font-size-64 font-weight-700 font-color-orange-50"}>
                                            20% OFF
                                        </label>
                                        <div>
                                            <label className={"font-size-14 font-color-orange-200"}>Only for PG
                                                residents</label>
                                        </div>
                                    </div>
                                    <div className={"mt-4 pt-md-3"}>
                                        <label
                                            className={"font-size-14 font-weight-500 discount-note-container font-family-inter font-color-neutral-white"}>Note:
                                            The gaming zone is open to everyone</label>
                                    </div>
                                </div>
                                <div className={"col-md-7 col-12 mt-4 mt-md-0 p-0"}>
                                    <div className={"w-100 h-100"}>
                                        <img className={"img-fluid h-100 w-100"}
                                             src={"/images/cafe/cafe-discount-image.png"} alt={"cafe discount image"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  available games  */}
                    <div className={"row py-md-4"}>
                        <div className={"col-12 my-4 py-2"}>
                            <div className={"row justify-content-center"}>
                                <div className={"col-10 p-0"}>
                                    <div className={"row"}>
                                        <div className={"col-12 d-flex justify-content-center"}>
                                            <label className={"font-size-42 font-weight-700 font-color-grey-900"}>Available
                                                Games</label>
                                        </div>
                                    </div>

                                    {gameData.map((each) => (
                                        each.available_games.map((game) => (
                                            <div className={"row mt-4 pt-3 justify-content-center"} key={game.id}>
                                                <div className={"col-md-5 mt-4 mt-md-0 order-1 order-md-0 col-12"}>
                                                    <div className={""}>
                                                        <label
                                                            className={"font-size-42 gaming-text-gradient font-weight-700 font-family-playfair"}>{game.game_name}</label>
                                                        <div
                                                            className={"font-size-14 font-weight-400 font-family-inter font-color-grey-400"}>
                                                            <label>
                                                                {game.game_description}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className={"mt-4"}>
                                                        <label
                                                            className={"font-size-32 font-weight-700 font-color-grey-950"}>{game.price}</label>
                                                    </div>
                                                    <div className={"mt-4"}>
                                                        <Link to={"/bookgame"}>
                                                            <button type={"button"}
                                                                    className={"bg-transparent button-border gaming-button-book-now font-color-theme-primary2 font-size-14 font-weight-500 font-family-inter"}>
                                                                Book now <Icon.ArrowRight/>
                                                            </button>
                                                        </Link>
                                                    </div>

                                                    <div className={"mt-4 pt-2"}>
                                                        <label
                                                            className={"font-size-18 font-weight-500 font-family-inter font-color-grey-900"}>Available
                                                            Table sizes</label>
                                                        <ul className={"font-color-grey-500 mt-2 font-size-14 font-weight-400 font-family-inter"}>
                                                            {game.available_table_sizes_prices.map((tables, index) => (
                                                                <li key={index}>
                                                                    <div className={"d-flex gap-4"}>
                                                                        <label>{tables.table}</label>
                                                                        <div>
                                                                            <label>-</label>
                                                                        </div>
                                                                        <div>
                                                                            <label
                                                                                className={"font-color-grey-900"}>{tables.table_price}</label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className={"mt-4"}>
                                                        <label
                                                            className={"font-size-18 font-weight-500 font-family-inter font-color-grey-900"}>Timings</label>
                                                        <div className={"mt-2"}>
                                                            <label
                                                                className={"font-size-14 font-weight-400 font-family-inter font-color-grey-500"}>
                                                                Starts from <span
                                                                className={"font-weight-500 font-color-grey-800"}>{game.timing_details[0].start_time}</span> to <span
                                                                className={"font-weight-500 font-color-grey-800"}>{game.timing_details[0].end_time}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <label
                                                            className={"font-size-12 font-weight-400 font-family-inter font-color-grey-50 gaming-discount-label align-items-center "}>
                                                            <img className={"img-fluid me-2"}
                                                                 src={"/images/gaming/discount-gift-box.png"}
                                                                 alt={"discount gift box"}/> Get 10% discount on Night
                                                            life
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className={"col-md-6 col-12"}>
                                                    <img className={"img-fluid md:br-10"} src={game.game_image}
                                                         alt={game.alt}/>
                                                </div>
                                            </div>
                                        ))
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<!--footer section-->*/}
                    <div className="row bg-footer-color justify-content-center">
                        <div className="col-12 col-md-10 py-4 my-4">
                            <div className="row  font-color-neutral-white ">
                                <div className="col-12">
                                    <div className="row justify-content-start font-family-inter">
                                        <div className="col-12 col-md-5">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-4 col-3">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"/images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-4 col-3 ps-0 p-md-0">
                                                            <label
                                                                className="font-size-14 font-weight-600 font-color-theme-primary2 font-family-inter">Le
                                                                Bestow Living</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="pt-4">
                                                        <label
                                                            className="font-size-14 font-weight-400 font-color-neutral-grey-lightest">©
                                                            2024 Bestow. All rights reserved.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="row ">
                                                <div
                                                    className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                                    <div className="pb-md-4 pb-3">
                                                        <button type="button"
                                                                className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Company
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <div className="pb-md-4 pb-3">
                                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                                <button type="button"
                                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Explore
                                                                    properties
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div className="pb-md-4 pb-3">
                                                            <Link to={"/about"}>
                                                                <button type="button"
                                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">About
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div className="pb-md-4 pb-3">
                                                            <Link to={"/contactus"}>
                                                                <button type="button"
                                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Contact
                                                                    us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12 col-md-4 px-md-0 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                                    <div className="pb-md-4 pb-3">
                                                        <button type="button"
                                                                className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Support
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <div className="pb-md-4 pb-3">
                                                            <a href="#">
                                                                <button type="button"
                                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Help
                                                                    center
                                                                </button>
                                                            </a>
                                                        </div>
                                                        <div className="pb-md-4 pb-3">
                                                            <a href="#">
                                                                <button type="button"
                                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Cancellation
                                                                    policy
                                                                </button>
                                                            </a>
                                                        </div>
                                                        <div className="pb-md-4 pb-3">
                                                            <a href="/termsandconditions">
                                                                <button type="button"
                                                                        className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Terms
                                                                    and conditions
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                                    <div className="pb-md-4 pb-3">
                                                        <button type="button"
                                                                className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Contact
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-column ">
                                                        <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                            <div>
                                                                <Icon.Phone className="text-white"/>
                                                            </div>
                                                            <label
                                                                className="font-size-14 font-weight-400">+91-9100009049</label>
                                                        </div>
                                                        <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                            <div>
                                                                <Icon.Mail className="text-white"/>
                                                            </div>
                                                            <div>
                                                                <a href="mailto:sales.bestowliving@gmail.com"
                                                                   className="font-size-14 font-color-neutral-white font-weight-400 text-decoration-none"> sales.bestowliving@gmail.com
                                                                </a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GamingPage
