import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import React, {useEffect, useRef, useState} from "react";
import 'bootstrap/dist/css/bootstrap.css'
import * as Icon from "react-feather";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from "swiper/modules";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/autoplay";

import LightGallery from "lightgallery/react";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import buildingDetails from '../Models/buildingDetails.json'
import {useParams} from "react-router-dom";
import propertiesList from "../Models/propertyDetails.json";
import {TailSpin} from "react-loader-spinner";


const AiraPropertyDetails = () => {

    const [propertyDetails, setPropertyDetails] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);

    const propertySlug = propertiesList[0].building_slug;

    const params = useParams();
    const id = params.id;

    useEffect(() => {
        const data = buildingDetails.find(each => each.slug === id);
        setPropertyDetails(data ? [data] : []);
        // console.log("property details", [data]);
    }, [id])

    const lightGalleryRef = useRef(null);

    const openLightGallery = () => {
        // Check if LightGallery instance exists
        if (lightGalleryRef.current) {
            // Call the open method of LightGallery to open it
            lightGalleryRef.current.openGallery();
        }
    };

    const handleSubmitButton = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const form = document.getElementById('contact-form');
        const data = new FormData(form);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,

        })
            .then(() => {
                alert("Success!");
                setLoadingButton(false);
                form.reset();
            }).catch(() => {
            alert("Failure!");
            setLoadingButton(false);
            form.reset();
        });
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {/*<!--  navbar  -->*/}
                    <div className='row justify-content-center fixed-top py-4'>
                        <div className={'col-md-10 col-11'}>
                            {['sm'].map((expand) => (
                                <Navbar key={expand} expand={expand}
                                        className="bg-body-tertiary bg-nav-bar-container mb-3">
                                    <Container fluid className={"row"}>
                                        <Navbar.Brand className={'col-6'}>
                                            <div className={"row"}>
                                                <div className="col-md-10 col-12 d-flex justify-content-center">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-2 col-5 pe-0 ">
                                                            <Link to={"/"}>
                                                                <img className="img-fluid"
                                                                     src={"/images/landing/co-live-logo-image.png"}
                                                                     alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-3 col-7 pe-0">
                                                            <div className={'w-100 text-wrap'}>
                                                                <label
                                                                    className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                    Bestow Living</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle className={'col-2 border-0'}
                                                       aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                        <Navbar.Offcanvas
                                            className={'col-12'}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="top"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                    <div className={'row'}>
                                                        <div className="col-5 d-flex justify-content-start">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-5 col-5 ps-0">
                                                                    <Link to={"/"}>
                                                                        <img className="img-fluid"
                                                                             src={"/images/landing/co-live-logo-image.png"}
                                                                             alt="logo"/>
                                                                    </Link>
                                                                </div>
                                                                <div className="col-7 p-0">
                                                                    <label
                                                                        className="font-size-14 font-weight-500 font-color-theme-primary1 font-family-inter">Le
                                                                        Bestow Living</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav
                                                    className="d-md-flex gap-3 justify-content-md-end align-items-center font-family-playfair flex-grow-1 pe-0">
                                                    <Nav>
                                                        <div>
                                                            <Link to={`/exploreproperties/${propertySlug}`}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">Explore
                                                                    properties
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/about"}>
                                                                <button type={"button"}
                                                                        className="bg-transparent border-0 font-size-14 font-weight-500 font-color-neutral-grey-darkest">About
                                                                    us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                    <Nav>
                                                        <div>
                                                            <Link to={"/contactus"}>
                                                                <button
                                                                    className="btn-bg btn-contact-us font-size-14 font-weight-500 font-color-neutral-white"
                                                                    type="button">Contact us
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Nav>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>
                            ))}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className={"py-4 my-4"}></div>
                            <div className="py-2"></div>
                        </div>
                    </div>
                    {/* hero section */}
                    <div className="row py-2">
                        <div className="col-12 py-4 my-md-4" id="lightGallery">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 px-3 px-0 ">
                                    <div className="row mb-3">
                                        <div className="col-12 p-md-0">
                                            {propertyDetails.map(each => (
                                                <div className="row d-md-flex justify-content-between" key={each.id}>
                                                    <div className="col-12 col-md-6 p-md-0">
                                                        <label
                                                            className="font-size-42 font-weight-700 font-color-grey-900">{each.name}</label>
                                                        <div
                                                            className="d-flex justify-content-start align-items-center gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/landing/map-location-icon.png"}
                                                                     alt="map icon"/>
                                                            </div>
                                                            <label
                                                                className="font-size-18 font-weight-500 font-color-grey-400 font-family-inter">{each.address}</label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12 col-md-5 px-md-0 d-flex align-items-center justify-content-md-end justify-content-between pt-4 mt-2 pt-md-0 mt-md-0">
                                                        <div className="pe-md-4 me-md-3">
                                                            <label
                                                                className="font-size-14 font-weight-400 font-color-grey-400 font-family-inter">Starting
                                                                from</label>
                                                            <div>
                                                                <label
                                                                    className="font-size-32 font-weight-600 font-color-theme-primary1">Rs {each.starting_from}/
                                                                    Month</label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Link to={"/contactus"}>
                                                                <button type="button"
                                                                        className="btn-bg font-size-14 font-weight-500 font-family-inter font-color-neutral-white btn-see-details">Contact
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* website light gallery  */}
                                    {propertyDetails.map(eachGallery => (
                                        <LightGallery
                                            ref={lightGalleryRef}
                                            speed={500}
                                            plugins={[lgThumbnail, lgZoom]}
                                            selector={".item"}
                                            elementClassNames={'aira-gallery'}
                                        >
                                            <div className="row pt-4 d-none d-md-flex" key={eachGallery.id}>
                                                <div className="col-12 p-0 d-flex justify-content-center">
                                                    <div className="row d-flex">
                                                        <div className="col-6 p-0 ">
                                                            <div className="row">
                                                                <div className="col-6 item"
                                                                     data-src={eachGallery.light_gallery[0].image_url}>
                                                                    <img className="img-fluid w-100 h-100 br-10"
                                                                         src={eachGallery.light_gallery[0].image_url}
                                                                         alt={eachGallery.light_gallery[0].alt}/>
                                                                </div>
                                                                <div className="col-6 ps-0 item"
                                                                     data-src={eachGallery.light_gallery[1].image_url}>
                                                                    <img className="img-fluid w-100 h-100 br-10"
                                                                         src={eachGallery.light_gallery[1].image_url}
                                                                         alt={eachGallery.light_gallery[1].alt}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 pe-0 item "
                                                             data-src={eachGallery.light_gallery[2].image_url}>
                                                            <img className="img-fluid w-100 h-100 br-10"
                                                                 src={eachGallery.light_gallery[2].image_url}
                                                                 alt={eachGallery.light_gallery[2].alt}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3 d-none d-md-flex">
                                                <div className="col-12 p-0">
                                                    <div className="row">
                                                        <div className="col-6 p-0 item"
                                                             data-src={eachGallery.light_gallery[3].image_url}>
                                                            <img className="img-fluid w-100 h-100 br-10"
                                                                 src={eachGallery.light_gallery[3].image_url}
                                                                 alt={eachGallery.light_gallery[3].alt}/>
                                                        </div>
                                                        <div className="col-6 pe-0">
                                                            <div className="row ">
                                                                <div className="col-6 pe-0">
                                                                    <div className="item  h-100"
                                                                         data-src={eachGallery.light_gallery[4].image_url}>
                                                                        <img className="img-fluid w-100 h-100 br-10"
                                                                             src={eachGallery.light_gallery[4].image_url}
                                                                             alt={eachGallery.light_gallery[4].alt}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="item  h-100"
                                                                         data-src={eachGallery.light_gallery[5].image_url}>
                                                                        <img className="img-fluid w-100 h-100 br-10"
                                                                             src={eachGallery.light_gallery[5].image_url}
                                                                             alt={eachGallery.light_gallery[5].alt}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="row justify-content-center bg-transparent view-photos-container">
                                                <div className="col-md-12 d-none d-md-flex justify-content-end ">
                                                    <div className="item"
                                                         data-src={eachGallery.light_gallery[1].image_url}>
                                                        <img className="img-flud d-none"
                                                             src={eachGallery.light_gallery[1].image_url}
                                                             alt={eachGallery.light_gallery[1].alt}/>

                                                        <button type="button" onClick={openLightGallery}
                                                                className="btn-see-details bg-white font-weight-500 font-size-14 font-color-theme-primary2 font-family-inter">View
                                                            all photos
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </LightGallery>
                                    ))}

                                    {/* mobile gallery */}
                                    <div className="row d-block d-md-none">
                                        <div className="col-12 h-100 pt-4 mt-4">
                                            <Swiper
                                                className="images-swiper"
                                                autoplay={true}
                                                slidesPerView={1}
                                                modules={[Autoplay, Pagination]}
                                                pagination={true}
                                                loop={true}
                                            >
                                                {propertyDetails.map((mobileGallery) => (
                                                    mobileGallery.mobile_gallery_sliders.map((slides) => (
                                                        <SwiperSlide>
                                                            <div className="">
                                                                <img className="img-fluid w-100 h-100 br-10"
                                                                     src={slides.image_url} alt={slides.alt}/>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                ))}

                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<!--  property amentites  -->*/}
                    <div className="row justify-content-center py-2">
                        <div className="col-12 col-md-10 px-3 px-md-0 py-4 my-md-4">
                            <div className="row d-md-flex">
                                <div className="col-12 col-md-6 h-100">
                                    {/*<!--  property amenities  -->*/}
                                    <div className="p-md-3 tabs-content-container br-10">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest font-size-32 font-weight-600">Amenities</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <Tabs
                                                defaultActiveKey="common"
                                                id="uncontrolled-tab-example"
                                                className="mb-5 d-flex justify-content-between"
                                            >
                                                <Tab eventKey="common" title="Common" className="">
                                                    <div className="row">
                                                        <div
                                                            className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/laundry-image.png"}
                                                                     alt="laundry image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Laundry</label>
                                                        </div>
                                                        <div
                                                            className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/wifi-image.png"}
                                                                     alt="wifi image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Wifi</label>
                                                        </div>
                                                        <div
                                                            className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/parking-image.png"}
                                                                     alt="parking image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Parking</label>
                                                        </div>
                                                        <div
                                                            className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/gym-image.png"}
                                                                     alt="gym image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Gym</label>
                                                        </div>
                                                        <div
                                                            className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/cctv-image.png"}
                                                                     alt="cctv image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">CCTV</label>
                                                        </div>
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/tv-image.png"} alt="tv image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">TV</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/water-purifier-image.png"}
                                                                     alt="water p image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Water
                                                                purifier</label>
                                                        </div>
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/hangout-area-image.png"} alt="hangout area image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Hangout area</label>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="room" title="Room">
                                                    <div className="row">
                                                        {/*<div className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/laundry-image.png"} alt="laundry image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Laundry</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/wifi-image.png"}
                                                                     alt="wifi image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Wifi</label>
                                                        </div>
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/parking-image.png"} alt="parking image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Parking</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/gym-image.png"} alt="gym image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Gym</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/cctv-image.png"} alt="cctv image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">CCTV</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/tv-image.png"}
                                                                     alt="tv image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">TV</label>
                                                        </div>
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/water-purifier-image.png"} alt="water p image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Water purifier</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/hangout-area-image.png"} alt="hangout area image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Hangout area</label>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="services" title="Services">
                                                    <div className="row">
                                                        {/*<div className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/laundry-image.png"} alt="laundry image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Laundry</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/wifi-image.png"} alt="wifi image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Wifi</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/parking-image.png"}
                                                                     alt="parking image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Parking</label>
                                                        </div>
                                                        <div
                                                            className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/gym-image.png"}
                                                                     alt="gym image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Gym</label>
                                                        </div>
                                                        <div
                                                            className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/cctv-image.png"}
                                                                     alt="cctv image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">CCTV</label>
                                                        </div>
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/tv-image.png"} alt="tv image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">TV</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/water-purifier-image.png"} alt="water p image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Water purifier</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/hangout-area-image.png"}
                                                                     alt="hangout area image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Hangout
                                                                area</label>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="food" title="Food">
                                                    <div className="row">
                                                        {/*<div className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/laundry-image.png"} alt="laundry image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Laundry</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-4 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/wifi-image.png"} alt="wifi image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Wifi</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/parking-image.png"} alt="parking image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Parking</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/gym-image.png"} alt="gym image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">Gym</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-4 pb-3  col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/cctv-image.png"} alt="cctv image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">CCTV</label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">*/}
                                                        {/*    <div>*/}
                                                        {/*        <img className="img-fluid" src={"/images/property-details/tv-image.png"} alt="tv image"/>*/}
                                                        {/*    </div>*/}
                                                        {/*    <label className="font-color-grey-800 font-size-14 font-weight-400">TV</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/water-purifier-image.png"}
                                                                     alt="water p image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Water
                                                                purifier</label>
                                                        </div>
                                                        <div
                                                            className="pb-md-0 pb-3 col-md-4 col-6 d-flex justify-content-start gap-2">
                                                            <div>
                                                                <img className="img-fluid"
                                                                     src={"/images/property-details/hangout-area-image.png"}
                                                                     alt="hangout area image"/>
                                                            </div>
                                                            <label
                                                                className="font-color-grey-800 font-size-14 font-weight-400">Hangout
                                                                area</label>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  Food menu  -->*/}
                                    <div className="food-menu tabs-content-container p-md-3 br-10">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">Food
                                                    Menu</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <Tabs
                                                defaultActiveKey="Sun"
                                                id="uncontrolled-tab-example"
                                                className="mb-5 d-flex justify-content-md-between nav-tabs-scrollable"
                                            >
                                                {propertyDetails.map((each) => (
                                                    each.food_menu.map((food) => (
                                                        <Tab eventKey={food.day} title={food.day} className="">
                                                            <div className="row">
                                                                <div className="col-12 col-md-4">
                                                                    <div className="row d-flex d-md-block">
                                                                        <div className="col-4 col-md-12">
                                                                            <div
                                                                                className="pb-4 d-block d-md-flex flex-row justify-content-start align-items-center gap-2">
                                                                                <div className="pb-2 pb-md-0">
                                                                                    <img className="img-fluid"
                                                                                         src={"/images/property-details/break-fast-image.png"}
                                                                                         alt="breakfast"/>
                                                                                </div>
                                                                                <label
                                                                                    className="font-color-theme-primary1 font-size-18 font-weight-500">Breakfast</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-8 col-md-12 pb-4">
                                                                            <div>
                                                                                <label
                                                                                    className="font-color-grey-950 font-size-14 font-weight-500">
                                                                                    {food.breakfast}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 ">
                                                                    <div className="row d-flex d-md-block">
                                                                        <div className="col-4 col-md-12">
                                                                            <div
                                                                                className="pb-4 d-block d-md-flex flex-row justify-content-start align-items-center gap-2">
                                                                                <div className="pb-2 pb-md-0">
                                                                                    <img className="img-fluid"
                                                                                         src={"/images/property-details/lunch-image.png"}
                                                                                         alt="lunch"/>
                                                                                </div>
                                                                                <label
                                                                                    className="font-color-theme-primary1 font-size-18 font-weight-500">Lunch</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-8 col-md-12 pb-4">
                                                                            <label
                                                                                className="font-color-grey-950 font-size-14 font-weight-500">
                                                                                {food.lunch}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 ">
                                                                    <div className="row d-flex d-md-block">
                                                                        <div className="col-4 col-md-12">
                                                                            <div
                                                                                className="pb-4 d-block d-md-flex flex-row justify-content-start align-items-center gap-2">
                                                                                <div className="pb-2 pb-md-0">
                                                                                    <img className="img-fluid"
                                                                                         src={"/images/property-details/dinner-image.png"}
                                                                                         alt="dinner"/>
                                                                                </div>
                                                                                <label
                                                                                    className="font-color-theme-primary1 font-size-18 font-weight-500">Dinner</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-8 col-md-12 pb-4">
                                                                            <label
                                                                                className="ffont-color-grey-950 font-size-14 font-weight-500">
                                                                                {food.dinner}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    ))
                                                ))}
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  Renting Terms  -->*/}
                                    <div className="">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">Renting
                                                    Terms</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <div className="row">
                                                <div className="col-12">
                                                    {propertyDetails.map(each => (
                                                        <div className="row d-block d-md-flex" key={each.id}>

                                                            <div className="col-12 col-md-6 pb-3 pb-md-0">
                                                                <div
                                                                    className="rent-container d-md-block d-flex justify-content-between align-items-center">
                                                                    <label
                                                                        className="font-size-14 font-color-theme-primary1 font-weight-400">Rent</label>
                                                                    <div className="pt-md-3">
                                                                        <label
                                                                            className="font-size-18 font-weight-500 font-color-grey-950">{each.renting_terms[0].rent}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 pb-3 pb-md-0">
                                                                <div
                                                                    className="rent-container d-md-block d-flex justify-content-between align-items-center">
                                                                    <label
                                                                        className="font-size-14 font-color-theme-primary1 font-weight-400">Lock
                                                                        In Period</label>
                                                                    <div className="pt-md-3">
                                                                        <label
                                                                            className="font-size-18 font-weight-500 font-color-grey-950">{each.renting_terms[0].lock_in_period}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="col-12 pt-md-4">
                                                    {propertyDetails.map(each => (
                                                        <div className="row d-block d-md-flex" key={each.id}>
                                                            <div className="col-12 col-md-6 pb-3 pb-md-0">
                                                                <div
                                                                    className="rent-container d-md-block d-flex justify-content-between align-items-center">
                                                                    <label
                                                                        className="font-size-14 font-color-theme-primary1 font-weight-400">Notice
                                                                        Period</label>
                                                                    <div className="pt-md-3">
                                                                        <label
                                                                            className="font-size-18 font-weight-500 font-color-grey-950">{each.renting_terms[0].notice_period} days</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 pb-3 pb-md-0">
                                                                <div
                                                                    className="rent-container d-md-block d-flex justify-content-between align-items-center">
                                                                    <label
                                                                        className="font-size-14 font-color-theme-primary1 font-weight-400">Electricity
                                                                        Unit</label>
                                                                    <div className="pt-md-3">
                                                                        <label
                                                                            className="font-size-18 font-weight-500 font-color-grey-950">{each.renting_terms[0].electricity_unit}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  Renting Packages  -->*/}
                                    <div className="">
                                        <div className="mb-4 pb-2">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">Renting
                                                    Terms</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <div className="row">
                                                <div className="col-12">
                                                    {propertyDetails.map(each => (
                                                        <div className="row" key={each.id}>
                                                            <div className="col-12 col-md-4 pb-3 pb-md-0">
                                                                <div
                                                                    className="rent-container d-flex justify-content-between d-md-block">
                                                                    <label
                                                                        className="font-size-14 font-color-theme-primary1 font-weight-400">Food</label>
                                                                    <div className="pt-md-3">
                                                                        <label
                                                                            className="font-size-18 font-weight-500 font-color-grey-950">{each.renting_terms[0].food}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 pb-3 pb-md-0">
                                                                <div
                                                                    className="rent-container d-flex justify-content-between d-md-block">
                                                                    <label
                                                                        className="font-size-14 font-color-theme-primary1 font-weight-400">Exit
                                                                        Charges</label>
                                                                    <div className="pt-md-3">
                                                                        <label
                                                                            className="font-size-18 font-weight-500 font-color-grey-950">{each.renting_terms[0].exit_charges}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <div
                                                                    className="rent-container d-flex justify-content-between d-md-block">
                                                                    <label
                                                                        className="font-size-14 font-color-theme-primary1 font-weight-400">AC
                                                                        Charges</label>
                                                                    <div className="pt-md-3">
                                                                        <label
                                                                            className="font-size-18 font-weight-500 font-color-grey-950">{each.renting_terms[0].ac_charges}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!-- Location container -->*/}
                                    <div className="">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest font-size-32 font-weight-600">Location</label>
                                            </div>
                                        </div>
                                        <div className="">
                                            {propertyDetails.map(each => (
                                                <iframe src={each.location}
                                                        style={{border: 0}}
                                                        className="w-100 h-100"
                                                        allowFullScreen="" loading="lazy"
                                                        referrerPolicy="no-referrer-when-downgrade">

                                                </iframe>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  Property Rules  -->*/}
                                    <div className="">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">Property
                                                    Rules</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <label className="font-size-14 font-weight-400 font-color-theme-primary1">
                                                Warden stay in the hostel 24*7, Couple Friendly, Smoking not allowed,
                                                Opposite Gender Friends Not Allowed, In-house Visitors Not Allowed,
                                                Overnight Stay Not Allowed, Alcohol Consumption Not Allowed, Pets Not
                                                Allowed
                                            </label>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  About  -->*/}
                                    <div className="">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">About</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <label className="font-size-14 font-weight-400 font-color-theme-primary1">
                                                Le Bestow Living - More than just living! We welcome you to our stay at
                                                Le Bestow
                                                Now like never before seen. STAY-PLAY-EAT all around at one place!!
                                                We are delighted to offer you the most hospitable welcome we can at our
                                                stay.
                                            </label>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  owner container  -->*/}
                                    <div
                                        className="bg-color-theme-primary-1 px-4 py-2 d-flex justify-content-start align-items-center br-5">
                                        <div className="pe-4">
                                            <img className="img-fluid" src={"/images/property-details/owner-image.png"}
                                                 alt="owner image"/>
                                        </div>
                                        <div className="d-flex flex-column gap-2 font-family-inter">
                                            <div>
                                                <label className="font-size-18 font-weight-400 font-color-grey-300">Property
                                                    Owner</label>
                                            </div>
                                            <label className="font-size-18 font-weight-400 font-color-neutral-white">Le
                                                BESTOW - AIRA</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 pt-4 mt-2 pt-md-0 mt-md-0 h-100">

                                    {/*<!--  view available rooms  -->*/}
                                    <div className="p-md-3 tabs-content-container br-10">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">Available
                                                    Rooms</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <Tabs
                                                defaultActiveKey="1st Floor"
                                                id="uncontrolled-tab-example"
                                                className="mb-5 d-flex justify-content-md-between nav-tabs-scrollable"
                                            >
                                                {propertyDetails.map((each) => (
                                                    each.floors.map((floor) => (
                                                        <Tab eventKey={floor.floor_name} title={floor.floor_name}
                                                             key={floor.floor_name}>
                                                            <div className="row">
                                                                <div
                                                                    className="col-12 d-flex flex-column gap-4 justify-content-start overflow-y-auto rooms-scrollbar"
                                                                    style={{height: "60vh"}}>
                                                                    {floor.rooms.map(eachRoom => (
                                                                        <Link to={"/contactus"}
                                                                              style={{textDecoration: "none"}}>
                                                                            <div className="row">
                                                                                <div className="col-8">
                                                                                    <div
                                                                                        className="d-flex justify-content-start align-items-start gap-4">
                                                                                        <div>
                                                                                            <img
                                                                                                className="img-fluid rounded-circle"
                                                                                                src={eachRoom.image_url}
                                                                                                alt={eachRoom.alt}/>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div>
                                                                                                <label
                                                                                                    className="font-size-18 font-weight-400 font-color-grey-800">{eachRoom.number}</label>
                                                                                            </div>
                                                                                            <label
                                                                                                className={`font-size-12 font-weight-500 ${eachRoom.available === true ? "font-color-green" : "font-color-theme-primary1"} `}>
                                                                                                {eachRoom.available === true ? (`${eachRoom.available_beds} of ${eachRoom.total_beds} bed is available to occupy`) : ('fully occupied')}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <div
                                                                                        className="w-100 d-flex justify-content-end">
                                                                                        <label
                                                                                            className="font-size-18 font-weight-400 font-color-grey-800">₹{eachRoom.price}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    ))
                                                ))}

                                            </Tabs>
                                        </div>
                                    </div>

                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  available rooms types  -->*/}
                                    <div className="p-md-3 tabs-content-container br-10">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">Available
                                                    Room Types</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">

                                            <Tabs
                                                defaultActiveKey="Single Sharing"
                                                id="uncontrolled-tab-example"
                                                className="mb-5 d-flex justify-content-md-between nav-tabs-scrollable"
                                            >
                                                {propertyDetails.map((each) => (
                                                    each.room_types.map((room) => (
                                                        <Tab eventKey={room.type} title={room.type} key={room.type}>
                                                            <div
                                                                className="row font-color-grey-800 font-size-18 font-weight-500 d-flex justify-content-between pb-4">
                                                                <div className="col-5 justify-content-start">
                                                                    <label>{room.type}</label>
                                                                </div>
                                                                <div className={"col-5 justify-content-end"}>
                                                                    <label>{room.price}</label>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="font-color-grey-800 font-size-18 font-weight-500 d-flex justify-content-between pb-3">
                                                                <label>Room Amenities</label>
                                                            </div>
                                                            <div className="row">
                                                                {room.amenities.map((images, index) => (
                                                                    <div className="d-flex flex-column col-md-4 col-6 "
                                                                         key={index}>
                                                                        <div
                                                                            className="pb-md-4 pb-3 d-flex justify-content-start align-items-center gap-2">
                                                                            <div>
                                                                                <img className="img-fluid"
                                                                                     src={images.icon}
                                                                                     alt={images.alt}/>
                                                                            </div>
                                                                            <label
                                                                                className="font-color-grey-800 font-size-14 font-weight-400">{images.name}</label>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </Tab>
                                                    ))
                                                ))}

                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!-- contact us -->*/}
                                    <div className="tabs-content-container p-md-3 br-10">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest font-size-32 font-weight-600">Contact
                                                    Us</label>
                                            </div>
                                        </div>
                                        <div className="font-family-inter">
                                            <form id="contact-form"
                                                  action="https://script.google.com/macros/s/AKfycbz3zJ43qGp3dY7m2Iksia7YshQnfM9QwEVl02sonQX-qxPUvleQN-HIeNzKeQBsqhLm/exec"
                                                  onSubmit={handleSubmitButton}>
                                                <div className="form-group">
                                                    <label htmlFor="formGroupExampleInput"
                                                           className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Name</label>
                                                    <input type="text" name="Name" required className="form-control"
                                                           id="formGroupExampleInput" placeholder="Enter your name"/>
                                                </div>
                                                <div className="form-group pt-4">
                                                    <label htmlFor="formGroupExampleInput2"
                                                           className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Email</label>
                                                    <input type="email" name="Email" required className="form-control"
                                                           id="formGroupExampleInput2" placeholder="Enter your email"/>
                                                </div>
                                                <div className="form-group pt-4">
                                                    <label htmlFor="formGroupExampleInput3"
                                                           className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Phone
                                                        number</label>
                                                    <input type="text" name="PhoneNumber" required
                                                           className="form-control" id="formGroupExampleInput3"
                                                           placeholder="Enter your phone number"/>
                                                </div>
                                                <div className="form-group pt-4">
                                                    <label htmlFor="formGroupExampleInput4"
                                                           className="pb-2 font-size-14 font-weight-500 font-color-neutral-grey-dark">Message
                                                        (optional)</label>
                                                    <textarea name="Message" rows="5" className="form-control"
                                                              id="formGroupExampleInput4"
                                                              placeholder="Message your doubts..."></textarea>
                                                </div>
                                                {!loadingButton ? <div className="pt-4 mt-2">
                                                    <button type="submit"
                                                            className="btn-bg btn-see-details font-color-neutral-white font-size-18 font-weight-500">Submit
                                                    </button>
                                                </div> : <div className="pt-4 mt-2">
                                                    <button type="button" disabled={true} className="btn-bg btn-see-details">
                                                        <TailSpin height={20} width={20} color={'#1977F3'}/>
                                                    </button>
                                                </div>}
                                            </form>
                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>

                                    {/*<!--  similar properties  -->*/}
                                    <div className="p-md-3 tabs-content-container br-10">
                                        <div className="mb-md-4 pb-md-2 pb-3">
                                            <div className="tabs-heading-container br-5 p-2">
                                                <label
                                                    className="font-color-neutral-grey-darkest  font-size-32 font-weight-600">Similar
                                                    Property</label>
                                            </div>
                                        </div>
                                        <div className="">
                                            {propertyDetails.map(each => (
                                                <div className="row d-flex flex-column gap-4" key={each.id}>
                                                    <div className="col-12">
                                                        <img className="img-fluid w-100 br-5"
                                                             src={each.similar_properties[0].image_url}
                                                             alt={each.similar_properties[0].alt}/>
                                                    </div>
                                                    <div className="col-12 content-container">
                                                        <div className="d-flex flex-column gap-2">
                                                            <label
                                                                className="font-size-32 font-weight-600 font-color-neutral-grey-darkest">{each.similar_properties[0].property_name}</label>
                                                            <div
                                                                className="d-flex flex-row align-items-center gap-2 font-family-inter">
                                                                <div className="">
                                                                    <i data-feather="map-pin"
                                                                       className="font-color-theme-primary2"></i>
                                                                </div>
                                                                <label
                                                                    className="font-size-18 font-weight-400 font-color-neutral-grey-dark">{each.similar_properties[0].address}</label>
                                                            </div>
                                                        </div>
                                                        <div className="pt-3">
                                                            <label
                                                                className="font-size-18 font-weight-500 font-color-neutral-grey-darkest font-family-inter">
                                                                <span
                                                                    className="font-weight-400 font-color-neutral-grey-dark">Starting from: </span>
                                                                Rs {each.similar_properties[0].starting_from}/ Bed
                                                            </label>
                                                        </div>
                                                        <div className="pt-3">
                                                            <Link
                                                                to={`/exploreproperties/${each.similar_properties[0].slug}`}>
                                                                <button type="button"
                                                                        className="btn-bg btn-see-details font-size-14 font-family-inter font-weight-500 font-color-neutral-white">See
                                                                    details
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="my-md-4 py-md-1 py-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<!--footer section-->*/}
                    <div className="row bg-footer-color justify-content-center">
                        <div className="col-12 col-md-10 py-4 my-4">
                            <div className="row font-color-neutral-white font-family-inter">
                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 col-md-10">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-4 col-3">
                                                    <Link to={"/"}>
                                                        <img className="img-fluid"
                                                             src={"/images/landing/co-live-logo-image.png"} alt="logo"/>
                                                    </Link>
                                                </div>
                                                <div className="col-md-4 col-3 ps-0 p-md-0">
                                                    <label
                                                        className="font-size-14 font-weight-600 font-color-theme-primary2 font-family-inter">Le
                                                        Bestow Living</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="pt-4">
                                                <label
                                                    className="font-size-14 font-weight-400 font-color-neutral-grey-lightest">©
                                                    2024 Bestow. All rights reserved.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="row ">
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Company
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={`/exploreproperties/${propertySlug}`}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Explore
                                                            properties
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/about"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">About
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={"/contactus"}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Contact
                                                            us
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-4 px-md-0 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Support
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={""}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Help
                                                            center
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={""}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Cancellation
                                                            policy
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="pb-md-4 pb-3">
                                                    <Link to={'/termsandconditions'}>
                                                        <button type="button"
                                                                className="font-size-14 font-color-neutral-white bg-transparent border-0 font-weight-400">Terms
                                                            and conditions
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 pt-4 mt-2 mt-md-0 pt-md-0 d-flex flex-column">
                                            <div className="pb-md-4 pb-3">
                                                <button type="button"
                                                        className="font-size-18 font-color-neutral-white bg-transparent border-0 font-weight-500">Contact
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Phone className="text-white"/>
                                                    </div>
                                                    <label
                                                        className="font-size-14 font-weight-400">+91-9100009059</label>
                                                </div>
                                                <div className="pb-md-4 pb-3 d-flex gap-2 align-items-center">
                                                    <div>
                                                        <Icon.Mail className="text-white"/>
                                                    </div>
                                                    <div>
                                                        <a href="mailto:sales.bestowliving@gmail.com"
                                                           className="font-size-14 font-color-neutral-white font-weight-400 text-decoration-none"> sales.bestowliving@gmail.com
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AiraPropertyDetails
